/* eslint-disable react/prop-types */
import React from 'react';
import {
  AFPTableRowAction,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';

import { useContractLine } from '../provider/contract-line-provider';
import {
  ReadyIcon,
  TableExpanderCell,
  TextInputField,
  NumberInputField,
  SelectDropdownField,
  MultiSelectDropdownField,
  CheckboxField,
  RadioGroupField,
  TextareaInputField,
} from '../helpers/ec-components';
import {
  CHARGE_TYPES,
  DC_FAST_CHARGE_TYPES,
  FAST_CHARGE_TYPE,
} from '../helpers/ec-helpers';
import {
  OPERATIONS,
  SUBJECTS,
  enDashUnicode,
} from '../../../../utilities/constants';

const SELECT_OPTION = { label: '-Select-', value: '' };

const findEngineByRow = (engineData, row) =>
  engineData.find(({ id }) => id === row.original.id);

// AFP table column generator for "Engine and fuel" tab
const ActionsCell = ({ row }) => {
  const { engineData, setEngine } = useContractLine();
  const ability = useAppAbility();
  const canUpdateContract = ability.can(OPERATIONS.Update, SUBJECTS.Contract);

  const original = findEngineByRow(engineData, row);

  const actDelivery = {
    icon: 'edit',
    label: 'Enter delivery info',
  };
  const actFuelData = {
    icon: 'edit',
    label: 'Enter fuel data',
  };
  const actClarification = {
    icon: 'edit',
    label: 'Add / edit clarification',
  };
  const actionsList = original.requiresOCONUS ? [] : [actDelivery];
  if (Object.keys(original.fuelInputMap).length > 0)
    actionsList.push(actFuelData);
  actionsList.push(actClarification);
  if (!canUpdateContract) return null;
  return (
    <AFPTableRowAction
      row={row}
      actions={actionsList}
      onSelectAction={() => {
        if (!row.original.isExpanded)
          setEngine(row.original.id, { table: { isExpanded: true } });
      }}
    />
  );
};

export const getEngineColumns = ({ onUpdate, readOnly }) => {
  return [
    {
      // Controlled expander cell
      id: 'expander', // needs an ID w/o Header
      sortable: false,
      headerClassName: 'cell-expander',
      Cell: ({ row }) => {
        const { setEngine } = useContractLine();
        return (
          <div style={{ padding: 0 }}>
            <TableExpanderCell
              row={row}
              onClick={(isExpanded) => {
                setEngine(row.original.id, { table: { isExpanded } });
              }}
            />
          </div>
        );
      },
    },
    {
      Header: 'Engines and base',
      accessor: 'equipment',
      sortable: false,
    },
    {
      Header: 'Engine model',
      sortable: false,
      Cell: ({ row }) => {
        const { engineData } = useContractLine();
        const original = findEngineByRow(engineData, row);
        return (
          <TextInputField
            original={original}
            onUpdate={onUpdate}
            field="engineModel"
            id="engine-model"
            label="Engine model"
            hideLabel
            disabled={readOnly}
          />
        );
      },
    },
    {
      Header: 'Fuel type',
      sortable: false,
      Cell: ({ row }) => {
        const { engineData, fuelTypes } = useContractLine();
        const original = findEngineByRow(engineData, row);
        const options = [SELECT_OPTION];
        fuelTypes.forEach((ft) =>
          options.push({ label: `${ft.code} ${ft.title}`, value: ft.id }),
        );
        return (
          <SelectDropdownField
            original={original}
            onUpdate={onUpdate}
            options={options}
            field="fuelTypeCodeInput"
            id="fuel-type"
            label="Fuel type"
            required
            hideLabel
            disabled={readOnly}
          />
        );
      },
    },
    {
      Header: 'Complete',
      sortable: false,
      Cell: ({ row }) => {
        const { engineData } = useContractLine();
        const original = findEngineByRow(engineData, row);
        return <ReadyIcon isReady={!!original.ready} />;
      },
    },
    {
      Header: 'Actions',
      sortable: false,
      Cell: ActionsCell,
    },
  ];
};

// Expanded row input field blocks
const DeliveryBlock = ({ original, onUpdate, isReadOnly }) => {
  const { states, isAwardedInFleet } = useContractLine();
  if (original.requiresOCONUS) return null;

  const stateOptions = states.map((st) => ({
    label: st.stateName,
    value: st.stateCode,
  }));
  const deliveryOptions = [
    { value: '50Plus5', label: '50 states and 5 U.S. territories' },
    { value: 'All50', label: '50 states only' },
    { value: 'Lower48', label: 'Lower 48 only' },
    { value: 'Other', label: 'Other' },
  ];

  return (
    <div className="margin-bottom-2">
      <div className="text-bold margin-bottom-1">
        CONUS delivery{' '}
        {!isReadOnly && isAwardedInFleet && <RequiredFieldIndicator />}
      </div>
      {isReadOnly ? (
        <span>
          {
            deliveryOptions.find(
              (v) => v.value === original.deliveryRegionType.newValue,
            )?.label
          }
        </span>
      ) : (
        <RadioGroupField
          original={original}
          onUpdate={onUpdate}
          options={deliveryOptions}
          field="deliveryRegionType"
          id="delivery-region"
          hideLabel
        />
      )}
      {original.deliveryRegionType.newValue === 'Other' && ( // TODO
        <div className="margin-top-05 margin-left-4">
          {isReadOnly ? (
            <span>
              {original.deliveryStatesInput.newValue
                .map(
                  (sCode) =>
                    states.find((s) => s.stateCode === sCode)?.stateName,
                )
                .join(', ')}
            </span>
          ) : (
            <MultiSelectDropdownField
              original={original}
              onUpdate={onUpdate}
              options={stateOptions}
              field="deliveryStatesInput"
              id="delivery-states"
              label="Select states or territories to which you deliver"
            />
          )}
        </div>
      )}
    </div>
  );
};

const DisplacementBlock = ({ original, onUpdate, isReadOnly }) => {
  if (!original.fuelInputMap.displacement) return null;
  return (
    <div className="margin-bottom-2">
      <div className="text-bold margin-bottom-1">Engine displacement</div>
      <div className="display-flex flex-row">
        <div className="margin-right-2 maxw-15">
          <NumberInputField
            original={original}
            onUpdate={onUpdate}
            field="engineLitresInput"
            id="engine-litres"
            label="Liters"
            required
            validate={(n) => n > 0}
            readonly={isReadOnly}
          />
        </div>
        <div className="maxw-15">
          <NumberInputField
            original={original}
            onUpdate={onUpdate}
            field="cylindersInput"
            id="cylinders"
            label="Cylinders"
            required
            isInt
            validate={(n) => n > 0}
            readonly={isReadOnly}
          />
        </div>
      </div>
    </div>
  );
};

const RangeBlock = ({ original, onUpdate, isReadOnly }) => {
  const { isAwardedInFleet } = useContractLine();
  if (!original.fuelInputMap.range && !original.fuelInputMap.rangeE)
    return null;
  const requireRangeTotal =
    isAwardedInFleet &&
    original.requiresMPG &&
    !original.requiresOCONUS &&
    !original.fuelInputMap.rangeE &&
    !original.isFuelDataUnknown.newValue;
  return (
    <div className="margin-bottom-2">
      <div className="text-bold margin-bottom-1">
        Range in {original.requiresOCONUS ? 'kilometers' : 'miles'} (estimated)
      </div>
      <div className="display-flex flex-row">
        {original.fuelInputMap.range && (
          <div className="margin-right-2 maxw-15">
            <NumberInputField
              original={original}
              onUpdate={onUpdate}
              field="rangeInput"
              id="total-range"
              label="Total"
              required={requireRangeTotal}
              isInt
              validate={(n) => n > 0}
              readonly={isReadOnly}
            />
          </div>
        )}
        {original.fuelInputMap.rangeE && (
          <div className="maxw-15">
            <NumberInputField
              original={original}
              onUpdate={onUpdate}
              field="rangeElectricInput"
              id="electric-range"
              label="All electric"
              required={isAwardedInFleet}
              isInt
              validate={(n) => n > 0}
              readonly={isReadOnly}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const MpgBlock = ({ original, onUpdate, fuelType, isReadOnly }) => {
  const { isAwardedInFleet } = useContractLine();
  if (!original.fuelInputMap[`mpg${fuelType}`]) return null;

  const header = original.requiresOCONUS ? 'Km/L' : 'MPG';
  const title = {
    C: `${header} conventional fuel`,
    A: `${header} alternative fuel`,
    E: `${header} electric`,
  };
  const getEmissionLabel = () => {
    if (!original.requiresOCONUS) return 'Grams/mi';
    return original.requiresGPTKM ? 'G/t-km' : 'G/km';
  };
  const required =
    isAwardedInFleet &&
    original.requiresMPG &&
    !original.requiresOCONUS &&
    !original.isFuelDataUnknown.newValue;
  return (
    <div className="margin-bottom-2">
      <div className="text-bold margin-bottom-1">{title[fuelType]}</div>
      <div className="display-flex flex-row">
        <div className="margin-right-2 maxw-15">
          <NumberInputField
            original={original}
            onUpdate={onUpdate}
            field={`mpg${fuelType}.cityInput`}
            id={`mpg-${fuelType}-city`}
            label="City"
            required={required}
            validate={(n) => n > 0}
            readonly={isReadOnly}
          />
        </div>
        <div className="margin-right-2 maxw-15">
          <NumberInputField
            original={original}
            onUpdate={onUpdate}
            field={`mpg${fuelType}.highwayInput`}
            id={`mpg-${fuelType}-highway`}
            label="Highway"
            required={required}
            isInt
            validate={(n) => n > 0}
            readonly={isReadOnly}
          />
        </div>
        <div className="margin-right-2 maxw-15">
          <NumberInputField
            original={original}
            onUpdate={onUpdate}
            field={`mpg${fuelType}.combinedInput`}
            id={`mpg-${fuelType}-combined`}
            label="Combined"
            required={required}
            validate={(n) => n > 0}
            readonly={isReadOnly}
          />
        </div>
        {fuelType !== 'E' && (
          <div className="margin-right-2 maxw-15">
            <NumberInputField
              original={original}
              onUpdate={onUpdate}
              field={`mpg${fuelType}.gpmiInput`}
              id={`mpg-${fuelType}-gpmi`}
              label={getEmissionLabel()}
              required={required}
              validate={(n) => n > 0}
              readonly={isReadOnly}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ChargingBlock = ({ original, onUpdate, isReadOnly }) => {
  const { isAwardedInFleet } = useContractLine();
  if (!original.fuelInputMap.charging) return null;

  const fcCapableOptions = [
    SELECT_OPTION,
    { label: 'Yes', value: 'Y' },
    { label: 'No', value: 'N' },
  ];
  const typeOptions = (original.requiresOCONUS
    ? CHARGE_TYPES.slice(1)
    : CHARGE_TYPES
  ).map((i) => ({ label: i, value: i }));
  return (
    <div className="margin-bottom-2">
      <div className="text-bold margin-bottom-1">Charging</div>
      <div
        className={`display-flex flex-row ${
          isReadOnly ? '' : 'flex-align-end'
        }`}
      >
        <div className="margin-right-2 width-card-lg">
          <MultiSelectDropdownField
            original={original}
            onUpdate={onUpdate}
            options={typeOptions}
            field="charging.typeInput"
            id="charge-types"
            label="Type of charging"
            readonly={isReadOnly}
            required={isAwardedInFleet}
          />
        </div>
        <div className="margin-right-2 width-card-lg">
          <SelectDropdownField
            original={original}
            onUpdate={onUpdate}
            options={fcCapableOptions}
            field="charging.isFastChargeCapableInput"
            id="fast-charge-capable"
            label="DC fast charge capable"
            required={isAwardedInFleet}
            readonly={isReadOnly}
            disabled={original.charging.typeInput.newValue.includes(
              FAST_CHARGE_TYPE,
            )}
          />
        </div>
        <div className="margin-right-2 width-card-lg">
          <NumberInputField
            original={original}
            onUpdate={onUpdate}
            field="charging.bkwhInput"
            id="charge-bkwh"
            label="Kilowatt hours (BKWH)"
            required={isAwardedInFleet}
            validate={(n) => n > 0}
            readonly={isReadOnly}
          />
        </div>
      </div>
    </div>
  );
};

const FastChargeTypeRow = ({ original, fcType, onUpdate, isReadOnly }) => {
  const { isAwardedInFleet } = useContractLine();
  const { checked, userEnteredType, chargeOption } = original.charging.fcTypes[
    fcType
  ];

  if (isReadOnly)
    return checked.newValue ? (
      <div className="grid-row">
        <div className="grid-col-2">
          {fcType === 'Other' ? userEnteredType.newValue || '(Other)' : fcType}
        </div>
        <div className="grid-col-2">
          {chargeOption.newValue || enDashUnicode}
        </div>
      </div>
    ) : null;

  const fcChargeOptions = [
    SELECT_OPTION,
    { label: 'Standard', value: 'Standard' },
    { label: 'Adapter', value: 'Adapter' },
  ];
  return (
    <div className="grid-row margin-top-1 minh-5">
      <div className="grid-col-3">
        <div className="display-flex flex-row padding-right-2">
          <CheckboxField
            original={original}
            onUpdate={onUpdate}
            field={`charging.fcTypes.${fcType}.checked`}
            id={`fc-type-${fcType}-checkbox`}
            label={fcType === 'Other' && checked ? '' : fcType}
            disabled={isReadOnly}
          />
          {fcType === 'Other' && checked && (
            <TextInputField
              original={original}
              onUpdate={onUpdate}
              field={`charging.fcTypes.${fcType}.userEnteredType`}
              id={`fc-type-${fcType}-user-type`}
              required={isAwardedInFleet}
              label="Type"
              hideLabel
              readonly={isReadOnly}
              placeholder="Enter type"
            />
          )}
        </div>
      </div>
      <div className="grid-col-2">
        {checked && (
          <SelectDropdownField
            original={original}
            onUpdate={onUpdate}
            options={fcChargeOptions}
            field={`charging.fcTypes.${fcType}.chargeOption`}
            id={`fc-type-${fcType}-option`}
            required={isAwardedInFleet}
            label="Option"
            hideLabel
            readonly={isReadOnly}
          />
        )}
      </div>
    </div>
  );
};

const FastChargeTypesBlock = ({ original, onUpdate, isReadOnly }) => {
  const { isAwardedInFleet } = useContractLine();
  const fcTypeList = original.requiresOCONUS
    ? DC_FAST_CHARGE_TYPES.slice(1)
    : [DC_FAST_CHARGE_TYPES[0], ...DC_FAST_CHARGE_TYPES.slice(2)];
  const hasSelection = fcTypeList.some(
    (fct) => original.charging.fcTypes[fct].checked.newValue,
  );
  return (
    <div className="margin-top-2">
      <div className="text-bold margin-bottom-1">DC fast charge types</div>
      <div className="grid-row">
        <div
          className={isReadOnly ? 'grid-col-2' : 'grid-col-3 padding-left-4'}
        >
          Type {!isReadOnly && isAwardedInFleet && <RequiredFieldIndicator />}
        </div>
        {(isReadOnly || hasSelection) && (
          <div className="grid-col-2">
            Option{' '}
            {!isReadOnly && isAwardedInFleet && <RequiredFieldIndicator />}
          </div>
        )}
      </div>
      {fcTypeList.map((fcType) => (
        <FastChargeTypeRow
          key={fcType}
          original={original}
          fcType={fcType}
          onUpdate={onUpdate}
          isReadOnly={isReadOnly}
        />
      ))}
    </div>
  );
};

const ClarificationBlock = ({ original, onUpdate, isReadOnly }) => {
  return (
    <div>
      <div className="text-bold margin-bottom-1">Clarification</div>
      <TextareaInputField
        original={original}
        onUpdate={onUpdate}
        field="explanation"
        id="explanation-textarea"
        label="Clarification"
        hideLabel
        characterLimit={500}
        disabled={isReadOnly}
      />
    </div>
  );
};

// Expanded row
export const EngineExpandedRow = ({ row, onUpdate, isReadOnly = false }) => {
  const { engineData } = useContractLine();
  const original = findEngineByRow(engineData, row);

  return (
    <div className="grid-row margin-y-1" key={original.id}>
      <div className="grid-col-3">
        <DeliveryBlock
          original={original}
          onUpdate={onUpdate}
          isReadOnly={isReadOnly}
        />
      </div>
      <div className="grid-col-9 padding-left-4">
        {Object.keys(original.fuelInputMap).length > 0 && (
          <div className="margin-bottom-2 full-width border-1px border-gray-10 padding-top-0 padding-x-2 padding-bottom-2">
            <CheckboxField
              original={original}
              onUpdate={onUpdate}
              field="isFuelDataUnknown"
              id="fuel-data-unknown"
              label="Fuel-related data is unknown at this time and will be provided within 60 days of contract award."
              disabled={isReadOnly}
            />
          </div>
        )}
        <div className="display-flex flex-row">
          <div className="margin-right-6">
            <DisplacementBlock
              original={original}
              onUpdate={onUpdate}
              isReadOnly={isReadOnly}
            />
            <RangeBlock
              original={original}
              onUpdate={onUpdate}
              isReadOnly={isReadOnly}
            />
          </div>
          <div>
            <MpgBlock
              original={original}
              onUpdate={onUpdate}
              fuelType="C"
              isReadOnly={isReadOnly}
            />
            <MpgBlock
              original={original}
              onUpdate={onUpdate}
              fuelType="A"
              isReadOnly={isReadOnly}
            />
            <MpgBlock
              original={original}
              onUpdate={onUpdate}
              fuelType="E"
              isReadOnly={isReadOnly}
            />
          </div>
        </div>
        <div className="margin-bottom-4">
          <ChargingBlock
            original={original}
            onUpdate={onUpdate}
            isReadOnly={isReadOnly}
          />
          {original.charging.isFastChargeCapableInput.newValue === 'Y' && (
            <FastChargeTypesBlock
              original={original}
              onUpdate={onUpdate}
              isReadOnly={isReadOnly}
            />
          )}
        </div>
        <ClarificationBlock
          original={original}
          onUpdate={onUpdate}
          isReadOnly={isReadOnly}
        />
      </div>
    </div>
  );
};
