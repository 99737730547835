import React from 'react';
import PropTypes from 'prop-types';
import {
  StandardFieldset,
  StandardFieldsetRow,
  StandardFieldsetRowCol,
} from './standard-fieldset';
import { getContractPeriod } from './contract-helpers';
import './contract-details.scss';

const contractShape = PropTypes.shape({
  contractNumber: PropTypes.string,
  contractYear: PropTypes.number,
  formalContractNumber: PropTypes.string,
  solicitation: PropTypes.shape({
    solicitationPeriods: PropTypes.arrayOf(PropTypes.shape({})),
    solicitationNumberLegacy: PropTypes.string,
    solicitationNumber: PropTypes.string,
  }),
  fleetAwarded: PropTypes.bool,
  contractUpiid: PropTypes.string,
  vendor: PropTypes.shape({
    id: PropTypes.string,
    vendorName: PropTypes.string,
  }),
  publishedInFleet: PropTypes.bool,
  solicitationPeriodId: PropTypes.number,
});

const ContractDetails = ({ contract }) => {
  const contractPeriod = getContractPeriod(
    contract?.solicitation?.solicitationPeriods,
    contract?.solicitationPeriodId,
  );

  return (
    <StandardFieldset className="contract-details">
      <StandardFieldsetRow>
        <StandardFieldsetRowCol
          colWidth={2}
          label="Internal contract number"
          data={contract.contractNumber}
        />
        <StandardFieldsetRowCol
          colWidth={2}
          label="Formal contract number"
          data={contract.formalContractNumber}
        />
        <StandardFieldsetRowCol
          colWidth={2}
          label="Solicitation uPIID"
          data={contract.solicitation?.solicitationNumber}
        />
        <StandardFieldsetRowCol
          colWidth={2}
          label="Federal vehicle standards year"
          data={contract.contractYear}
        />
        <StandardFieldsetRowCol
          colWidth={2}
          label="Period"
          data={contractPeriod}
        />
        <StandardFieldsetRowCol
          colWidth={2}
          label="Awarded in GSA Fleet"
          data={contract.fleetAwarded ? 'Yes' : 'No'}
        />
        {/* <StandardFieldsetRowCol
        colWidth={2}
        label="Publish"
        data={contract.publishedInFleet}
      /> */}
      </StandardFieldsetRow>
    </StandardFieldset>
  );
};

ContractDetails.propTypes = {
  contract: contractShape.isRequired,
};

export default ContractDetails;
