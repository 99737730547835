import React, { useMemo } from 'react';
import { AccordionItem, Button, AFPTable } from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useContractLine } from '../provider/contract-line-provider';
import {
  AccordionTitle,
  getHandelToggle,
  getToggleAccordion,
} from '../helpers/ec-components';
import {
  INPUT_TYPE_CODE,
  INPUT_DROPDOWN_OTHER_OPTION,
  CRITERIA_CODE,
  isMinReqItemReady,
  getValueObj,
  getUpdateObj,
} from '../helpers/ec-helpers';
import { getMinReqColumns, EcExpandedRow } from '../helpers/table-defs-ec';

import { OPERATIONS, SUBJECTS } from '../../../../utilities/constants';

const MinimumRequirements = () => {
  const {
    contractLine,
    standardECs,
    standardECTable: cats,
    setStandardECTable,
    setStandardEC,
  } = useContractLine();

  const toggleAccordion = getToggleAccordion(setStandardECTable);
  const handleToggle = getHandelToggle(setStandardECTable);

  const ability = useAppAbility();
  const canUpdateContract = ability.can(OPERATIONS.Update, SUBJECTS.Contract);

  const updateItem = async (original, updates) => {
    const updateObj = getUpdateObj(original, updates);
    const data = { ...updateObj };
    const newLine = { ...original, ...updateObj };
    data.ready = isMinReqItemReady(newLine.inputValue, newLine.comply);
    if (updates.comply === 'Y') data.inputException = getValueObj('');
    const table =
      newLine.comply.newValue === 'N' ? { isExpanded: true } : undefined;
    setStandardEC(original, { data, table });
  };

  const onUpdate = (original, type, value) => {
    const { criteriaCode, lowerBound, upperBound } = original;
    let comply;
    switch (type) {
      case 'comply':
        updateItem(original, { comply: value });
        break;
      case INPUT_TYPE_CODE.DROPDOWN:
        if (value === INPUT_DROPDOWN_OTHER_OPTION.value) comply = 'N';
        else comply = value ? 'Y' : '';
        updateItem(original, { inputValue: value, comply });
        break;
      case INPUT_TYPE_CODE.COMPLY:
        updateItem(original, { inputValue: value, comply: value });
        break;
      case INPUT_TYPE_CODE.NUMERICAL:
        if (value)
          comply =
            lowerBound == null ||
            (criteriaCode === CRITERIA_CODE.MIN && value >= lowerBound) ||
            (criteriaCode === CRITERIA_CODE.MAX && value <= lowerBound) ||
            value - lowerBound === 0
              ? 'Y'
              : 'N';
        else comply = '';
        updateItem(original, { inputValue: value, comply });
        break;
      case INPUT_TYPE_CODE.RANGE:
        if (value)
          comply =
            (lowerBound == null || value >= lowerBound) &&
            (upperBound == null || value <= upperBound)
              ? 'Y'
              : 'N';
        else comply = '';
        updateItem(original, { inputValue: value, comply });
        break;
      case INPUT_TYPE_CODE.TEXT:
        updateItem(original, { inputValue: value });
        break;
      default:
        // case 'inputException' and 'inputExplanation':
        updateItem(original, { [type]: value });
    }
  };

  const columns = useMemo(
    () => getMinReqColumns(onUpdate),
    [onUpdate],
    canUpdateContract,
  );
  const subrow = useMemo(
    // eslint-disable-next-line
    () => ({ row }) => (
      <EcExpandedRow
        ecType="S"
        row={row}
        onUpdate={onUpdate}
        isReadOnly={!canUpdateContract}
      />
    ),
    [onUpdate],
  );

  return (
    <div className="margin-bottom-8 contract-line-template">
      <div className="title-m-bold">Minimum requirements</div>
      <div className="display-flex flex-row flex-justify flex-align-end margin-bottom-4">
        <div style={{ maxWidth: 760 }}>
          {contractLine.standardItem.content?.content}
        </div>
        <div>
          <Button
            variant="unstyled"
            label="Open/close all"
            leftIcon={{ name: 'unfold_more' }}
            onClick={toggleAccordion}
          />
        </div>
      </div>

      {cats.map((cat, i) => {
        const isReady = standardECs[cat.index].data.every((row) => row.ready);
        return (
          <AccordionItem
            key={cat.id}
            id={cat.id}
            title={<AccordionTitle title={cat.title} isReady={isReady} />}
            expanded={cat.expanded}
            content={
              cat.expanded && (
                <div style={{ paddingTop: 8 }}>
                  <AFPTable
                    fullWidth
                    columns={columns}
                    data={cat.data}
                    renderRowSubComponent={subrow}
                  />
                </div>
              )
            }
            handleToggle={() => handleToggle(i)}
          />
        );
      })}
    </div>
  );
};

export default MinimumRequirements;
