import React, { useCallback } from 'react';
import {
  RequiredFieldIndicator,
  FlexView,
  TextInput,
} from '@gsa/afp-component-library';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useForm, Controller } from 'react-hook-form';
import { useContractLine } from '../provider/contract-line-provider';
import LineMakeModelSection from './make-model/line-make-model';
import {
  enDashUnicode,
  emSpaceUnicode,
  OPERATIONS,
  SUBJECTS,
} from '../../../../utilities/constants';
import StartEndDateTimePicker from './start-end-date-time-picker';

const validationSchema = yup.object().shape({
  quantity: yup
    .number()
    .transform((value) =>
      value === '' || Number.isNaN(value) ? undefined : value,
    )
    .moreThan(0, 'Allocation quantity must be 1 or more.'),
});

const LineSection = () => {
  const { contractLine, lineTabData, setLineTabData } = useContractLine();
  const { control, register, errors, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      quantity: lineTabData?.quantity || contractLine?.quantity,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const ability = useAppAbility();
  const canUpdateContract = ability.can(OPERATIONS.Update, SUBJECTS.Contract);

  const genLabel = (label, required) => (
    <span className="text-bold" data-testid={label}>
      {label} {required && <RequiredFieldIndicator />}
    </span>
  );

  const estimatedTotalQuantity =
    contractLine?.contractLineFinancialRef?.estimatedTotalQuantity;
  const estimatedFleetQuantity =
    contractLine?.contractLineFinancialRef?.estimatedFleetQuantity;

  const onFieldChange = useCallback((filedName, event) => {
    setValue(filedName, event.target.value, { shouldValidate: true });
  }, []);

  const onFieldBlur = useCallback(
    (fieldName, event) => {
      const newValue = event.target.value;
      const currentValue =
        contractLine?.[fieldName] || lineTabData?.[fieldName] || '';
      if (String(newValue) === String(currentValue)) {
        return;
      }
      setLineTabData({
        ...lineTabData,
        [fieldName]: newValue,
      });
    },
    [lineTabData],
  );

  return (
    <>
      <LineMakeModelSection />
      <StartEndDateTimePicker />

      <div>
        <Controller
          name="quantity"
          control={control}
          render={({ value }) => (
            <div>
              <TextInput
                name="quantity"
                ref={register}
                value={value === 0 ? emSpaceUnicode : value}
                data-testid="quantity"
                label={genLabel('Allocation', false)}
                type="number"
                min={0}
                errorMessage={errors.quantity && errors.quantity.message}
                onChange={(event) => onFieldChange('quantity', event)}
                onBlur={(e) => onFieldBlur('quantity', e)}
                disabled={!canUpdateContract}
              />
            </div>
          )}
        />
      </div>

      <FlexView className="margin-top-4">
        <div className="margin-right-8">
          <div className="text-bold">Estimated total quantity</div>
          <div>{estimatedTotalQuantity || enDashUnicode}</div>
        </div>
        <div>
          <div className="text-bold">Estimated fleet quantity</div>
          <div>{estimatedFleetQuantity || enDashUnicode}</div>
        </div>
      </FlexView>
    </>
  );
};

export default LineSection;
