import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import '@gsa/afp-component-library/dist/css/index.css';
import { DefaultLayout } from '@gsa/afp-component-library';
import {
  AppProvider,
  NotFound,
  PageFocus,
  PublicRoute,
  Unauthorized,
  FeatureProvider,
  PrivateRoute,
} from '@gsa/afp-shared-ui-utils';
import Layout from './utilities/Layout';
import LogoutPage from './pages/auth/logout';
import StandardItems from './pages/standard-items';
import EquipmentCode from './pages/equipment-code';
import CatalogCodes from './pages/catalog-codes';
import StandardItemDetail from './pages/standard-items-detail';
import { NotificationProvider } from './utilities/notification/notification-provider';
import GET_ENABLED_CATALOG_FEATURES from './utilities/feature-toggle/enabled-features-query';
import dataStore, { sharedGatewayClient } from './services/data-store';
import EquipmentCodeDetail from './pages/equipment-codes-detail';
import TaskDetail from './pages/task-detail';
import { SUBJECTS, OPERATIONS, LOCALSTORAGE_KEYS } from './utilities/constants';
import './App.scss';
import FedVehicleStandards from './pages/fvs';
import FedVehicleStandardsPublic from './pages/fvs-public';

import CommentsAdmin from './pages/comment-admin';
import ViewComments from './pages/comment-admin/view-comments';
import Solicitations from './pages/solicitation';
import validateProcess from './utilities/feature-toggle/validate-process';
import NewSolicitation from './pages/solicitation/add-edit-solicitation/add-solicitaion/upiid-details';
import OpenPeriod from './pages/solicitation/add-edit-solicitation/add-solicitaion/open-period';
import ReopenPeriod from './pages/solicitation/add-edit-solicitation/add-solicitaion/re-open-periods';
import SolicitationSINSelection from './pages/solicitation/add-edit-solicitation/add-solicitaion/select-si/index';
import EditSolicitationSINSelection from './pages/solicitation/add-edit-solicitation/edit-solicitation/select-si/index';
import AssignQuantityToStandardItems from './pages/solicitation/add-edit-solicitation/common/assign-quantities';
import ReviewSolicitation from './pages/solicitation/add-edit-solicitation/common/review';
import ViewSolicitation from './pages/solicitation/view-solicitation';
import Bids from './pages/bids';
import BidDashboard from './pages/bid-dashboard';
import BidLineReviewAndSubmit from './pages/bid-dashboard/bid-line-details/review-and-submit';
import BidLineDetails from './pages/bid-dashboard/bid-line-details';
import SelectStandardItemForBid from './pages/bids/add-standard-items/index';
import EditSolicitation from './pages/solicitation/add-edit-solicitation/edit-solicitation/upiid-details';
import MakeModelApproval from './pages/make-model-approval';
import AccessControlledRoute from './utilities/access-controlled-route/access-controlled-route';
import BidHistory from './pages/bids/bid-history';
import BidReports from './pages/bids/bid-reports';
import BidEvaluation from './pages/bid-evaluation/bid-search';
import BidLineEvaluation from './pages/bid-evaluation/bid-line-evaluation';
import GlobalErrorMessages from './pages/error/global-error-messages';
import Contract from './pages/contract';
import { useFeatureToggle } from './utilities/feature-toggle';

function App() {
  const { feature } = useFeatureToggle();
  // Clear local storage keys for Standard Items and Equipment Codes, Catalog Code pages.
  LOCALSTORAGE_KEYS.forEach((storagekey) => {
    window.localStorage.removeItem(storagekey.key);
  });

  return (
    <Router>
      <Switch>
        <PublicRoute
          path="/public"
          component={() => {
            return (
              <RecoilRoot>
                <ApolloProvider client={sharedGatewayClient}>
                  <DefaultLayout
                    currentUser={undefined}
                    menu={[
                      {
                        title: 'Home',
                        linkUrl: `${window.AFP_CONFIG.appURLs.home}`,
                        isHidden: false,
                        permission: null,
                        childNavigationItems: [],
                      },
                      {
                        title: 'Help',
                        linkUrl: `${window.AFP_CONFIG.appURLs.home}/help`,
                        isHidden: false,
                        permission: null,
                        childNavigationItems: [],
                      },
                    ]}
                  >
                    <GlobalErrorMessages />
                    <FedVehicleStandardsPublic />
                  </DefaultLayout>
                </ApolloProvider>
              </RecoilRoot>
            );
          }}
        />

        <PublicRoute
          paht="/catalog"
          component={() => {
            return (
              <RecoilRoot>
                <ApolloProvider client={dataStore}>
                  <AppProvider>
                    <FeatureProvider
                      featureQuery={GET_ENABLED_CATALOG_FEATURES}
                    >
                      <NotificationProvider>
                        <Layout>
                          <Router
                            getUserConfirmation={() => {
                              /* Empty callback to block the default browser prompt */
                            }}
                          >
                            <PageFocus />
                            <Switch>
                              <PublicRoute
                                exact
                                path="/logout"
                                component={LogoutPage}
                              />
                              <PublicRoute
                                exact
                                path="/unauthorized"
                                component={Unauthorized}
                                title="Access Denied"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/standard-items"
                                component={StandardItems}
                                title="Standard Items"
                                operation={OPERATIONS.Manage}
                                subject={SUBJECTS.StandardItem}
                                validate={validateProcess}
                                featureName="STANDARD_ITEM"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/standard-item/:key"
                                component={StandardItemDetail}
                                title="Standard Items-Detail"
                                operation={OPERATIONS.Manage}
                                subject={SUBJECTS.StandardItem}
                                validate={validateProcess}
                                featureName="STANDARD_ITEM"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/equip-codes"
                                component={EquipmentCode}
                                title="Equipment Codes"
                                operation={OPERATIONS.Manage}
                                subject={SUBJECTS.EquipmentCodes}
                                validate={validateProcess}
                                featureName="EQUIPMENT_CODE"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/equip-codes/:year/:program/:code"
                                component={EquipmentCodeDetail}
                                title="Equipment Code Detail"
                                operation={OPERATIONS.Manage}
                                subject={SUBJECTS.EquipmentCodes}
                                validate={validateProcess}
                                featureName="EQUIPMENT_CODE"
                              />
                              <PrivateRoute
                                path="/catalog/codes"
                                component={CatalogCodes}
                                title="Catalog Codes"
                                operation={OPERATIONS.View}
                                subject={SUBJECTS.CatalogCodes}
                                validate={validateProcess}
                                featureName="CATALOG_CODE"
                              />
                              <PrivateRoute
                                path="/catalog/vehicle-standards"
                                component={FedVehicleStandards}
                                validate={validateProcess}
                                featureName="FEDERAL_VEHICLE_STANDARD"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/tasks/:id"
                                component={TaskDetail}
                                title="Peer Review"
                                validate={validateProcess}
                                featureName="FEDERAL_VEHICLE_STANDARD"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/comments-admin"
                                component={CommentsAdmin}
                                title="Comments Admin"
                                operation={OPERATIONS.Manage}
                                subject={SUBJECTS.FVSComments}
                                validate={validateProcess}
                                featureName="FEDERAL_VEHICLE_STANDARD"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/comments"
                                component={ViewComments}
                                operation={OPERATIONS.View}
                                subject={SUBJECTS.FVSComments}
                                title="Comments"
                                validate={validateProcess}
                                featureName="FEDERAL_VEHICLE_STANDARD"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/solicitations"
                                component={Solicitations}
                                operation={OPERATIONS.Manage}
                                subject={SUBJECTS.Solicitation}
                                title="Solicitations"
                                validate={validateProcess}
                                featureName="SOLICITATION"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/solicitations/new-solicitation"
                                component={NewSolicitation}
                                operation={OPERATIONS.Create}
                                subject={SUBJECTS.Solicitation}
                                title="New solicitation"
                                validate={validateProcess}
                                featureName="SOLICITATION"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/solicitations/new-solicitation/:id/upiid-details"
                                component={NewSolicitation}
                                operation={OPERATIONS.Create}
                                subject={SUBJECTS.Solicitation}
                                title="New solicitation"
                                validate={validateProcess}
                                featureName="SOLICITATION"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/solicitations/edit-solicitation/:id/upiid-details"
                                component={EditSolicitation}
                                operation={OPERATIONS.Create}
                                subject={SUBJECTS.Solicitation}
                                title="Edit solicitation"
                                validate={validateProcess}
                                featureName="SOLICITATION"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/solicitations/new-solicitation/:id/open-period"
                                component={OpenPeriod}
                                operation={OPERATIONS.Create}
                                subject={SUBJECTS.Solicitation}
                                title="New solicitation"
                                validate={validateProcess}
                                featureName="SOLICITATION"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/solicitations/edit-solicitation/:id/open-period"
                                component={OpenPeriod}
                                operation={OPERATIONS.Create}
                                subject={SUBJECTS.Solicitation}
                                title="Edit solicitation"
                                validate={validateProcess}
                                featureName="SOLICITATION"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/solicitations/new-solicitation/:id/reopen-period"
                                component={ReopenPeriod}
                                operation={OPERATIONS.Create}
                                subject={SUBJECTS.Solicitation}
                                title="New solicitation"
                                validate={validateProcess}
                                featureName="SOLICITATION"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/solicitations/edit-solicitation/:id/reopen-period"
                                component={ReopenPeriod}
                                operation={OPERATIONS.Create}
                                subject={SUBJECTS.Solicitation}
                                title="Edit solicitation"
                                validate={validateProcess}
                                featureName="SOLICITATION"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/solicitations/new-solicitation/:id/select-sin"
                                component={SolicitationSINSelection}
                                operation={OPERATIONS.Create}
                                subject={SUBJECTS.Solicitation}
                                title="Select Standard Item"
                                validate={validateProcess}
                                featureName="SOLICITATION"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/solicitations/edit-solicitation/:id/select-sin"
                                component={EditSolicitationSINSelection}
                                operation={OPERATIONS.Create}
                                subject={SUBJECTS.Solicitation}
                                title="Select Standard Item"
                                validate={validateProcess}
                                featureName="SOLICITATION"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/solicitations/new-solicitation/:id/assign-quantity"
                                component={AssignQuantityToStandardItems}
                                operation={OPERATIONS.Create}
                                subject={SUBJECTS.Solicitation}
                                title="Assign quantity"
                                validate={validateProcess}
                                featureName="SOLICITATION"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/solicitations/edit-solicitation/:id/assign-quantity"
                                component={AssignQuantityToStandardItems}
                                operation={OPERATIONS.Create}
                                subject={SUBJECTS.Solicitation}
                                title="Assign quantity"
                                validate={validateProcess}
                                featureName="SOLICITATION"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/solicitations/new-solicitation/:id/review"
                                component={ReviewSolicitation}
                                operation={OPERATIONS.Create}
                                subject={SUBJECTS.Solicitation}
                                title="Review"
                                validate={validateProcess}
                                featureName="SOLICITATION"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/solicitations/edit-solicitation/:id/review"
                                component={ReviewSolicitation}
                                operation={OPERATIONS.Create}
                                subject={SUBJECTS.Solicitation}
                                title="Review"
                                validate={validateProcess}
                                featureName="SOLICITATION"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/solicitations/:id"
                                component={ViewSolicitation}
                                operation={OPERATIONS.View}
                                subject={SUBJECTS.Solicitation}
                                title="View solicitation"
                                validate={validateProcess}
                                featureName="SOLICITATION"
                              />
                              <PrivateRoute
                                exact
                                path="/catalog/bids"
                                component={Bids}
                                operation={OPERATIONS.Create}
                                // TODO change the subject to VehicleSupplier
                                // subject={SUBJECTS.PSOQuestionnaire}
                                title="Bids"
                                validate={validateProcess}
                                featureName="BIDS"
                              />
                              <AccessControlledRoute
                                exact
                                path="/catalog/bids/bid-center/bid-history"
                                component={BidHistory}
                                operation={OPERATIONS.Create}
                                // TODO change the subject to VehicleSupplier
                                // subject={SUBJECTS.PSOQuestionnaire}
                                title="Bid history"
                                validate={validateProcess}
                                featureName="BIDS"
                              />
                              <AccessControlledRoute
                                exact
                                path="/catalog/bids/:bidId/:solId/add-standard-items"
                                component={SelectStandardItemForBid}
                                operation={OPERATIONS.Create}
                                // TODO: change the subject to VehicleSupplier
                                // subject={SUBJECTS.PSOQuestionnaire}
                                title="Add Standard Items"
                                validate={validateProcess}
                                featureName="BIDS"
                              />
                              <PrivateRoute
                                path="/catalog/solicitations/:solId/bid-dashboard/:bidId"
                                component={BidDashboard}
                                title="Bid Dashboard"
                                validate={validateProcess}
                                featureName="BIDS"
                                exact
                              />
                              <AccessControlledRoute
                                path="/catalog/solicitations/:solId/bid-dashboard/:bidId/bid-line-details/:bidLineId/review-and-submit"
                                component={BidLineReviewAndSubmit}
                                title="Bid line review and submit page"
                                validate={validateProcess}
                                featureName="BIDS"
                                exact
                              />
                              <PrivateRoute
                                path="/catalog/solicitations/:solId/bid-dashboard/:bidId/bid-line-details/:bidLineId"
                                component={BidLineDetails}
                                title="Bid line details"
                                validate={validateProcess}
                                featureName="BIDS"
                                exact
                              />
                              <PrivateRoute
                                operation="manage"
                                subject="MakeModel"
                                exact
                                path="/catalog/makes-and-models"
                                title="Makes and Models"
                                component={MakeModelApproval}
                              />
                              <PrivateRoute
                                operation="view"
                                subject="BidReport"
                                exact
                                path="/catalog/bid-reports"
                                title="Bid Reports"
                                component={BidReports}
                              />
                              <PrivateRoute
                                operation="review"
                                subject="Bid"
                                exact
                                path="/catalog/proposal-evaluation"
                                title="Proposal Evaluation"
                                component={BidEvaluation}
                              />
                              <PrivateRoute
                                operation="review"
                                subject="Bid"
                                exact
                                path="/catalog/proposal-evaluation/bid/:bidId"
                                title="Line Items Evaluation"
                                component={BidLineEvaluation}
                              />
                              <PrivateRoute
                                operation={OPERATIONS.View}
                                subject={SUBJECTS.Contract}
                                path="/catalog/contract"
                                component={feature(Contract, 'contracts')}
                              />

                              <PublicRoute
                                path="*"
                                component={NotFound}
                                title="Page Not Found"
                              />
                            </Switch>
                          </Router>
                        </Layout>
                      </NotificationProvider>
                    </FeatureProvider>
                  </AppProvider>
                </ApolloProvider>
              </RecoilRoot>
            );
          }}
        />
      </Switch>
    </Router>
  );
}

export default App;
