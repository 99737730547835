import React, { useCallback, useState } from 'react';
import moment from 'moment';
import {
  FlexView,
  DatePicker,
  TimePicker,
  SelectDropdown,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useContractLine } from '../provider/contract-line-provider';
import { OPERATIONS, SUBJECTS } from '../../../../utilities/constants';

const StartEndDateTimePicker = () => {
  const { contractLine, lineTabData, setLineTabData } = useContractLine();
  const ability = useAppAbility();
  const canUpdateContract = ability.can(OPERATIONS.Update, SUBJECTS.Contract);

  const startDateStr =
    lineTabData?.orderStartDate || contractLine?.orderStartDate;
  const startDate = startDateStr ? moment(startDateStr) : null;
  const formattedStartDate = startDate?.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  const formattedStartTime = startDate?.format('HH:mm');

  const endDateStr = lineTabData?.orderEndDate || contractLine?.orderEndDate;
  const endDate = endDateStr ? moment(endDateStr) : null;
  const formattedEndDate = endDate?.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  const formattedEndTime = endDate?.format('HH:mm');
  const isDirect = contractLine?.isDirect;

  // bellow is used to work around a bug in the DatePicker component, which calls on change on intial render
  const [firstDateComChange, setFirstDateComChange] = useState({
    // eslint-disable-next-line
    orderStartDate: formattedStartDate ? true : false,
    // eslint-disable-next-line
    orderStartDateTime: formattedStartTime ? true : false,
    // eslint-disable-next-line
    orderEndDate: formattedEndDate ? true : false,
    // eslint-disable-next-line
    orderEndDateTime: formattedEndTime ? true : false,
  });

  const onDateChange = useCallback(
    (fieldKey, value) => {
      const isFirstInitialOnChange = firstDateComChange[fieldKey];
      if (isFirstInitialOnChange) {
        setFirstDateComChange((prev) => ({
          ...prev,
          [fieldKey]: false,
        }));
        return;
      }
      if (!value) {
        setLineTabData((prev) => ({
          ...prev,
          [fieldKey]: null,
        }));
        return;
      }
      const date =
        (fieldKey === 'orderStartDate' ? startDate : endDate) ||
        moment().startOf('day');

      const parsedDate = moment(value, 'MM/DD/YYYY');
      const day = parsedDate.date();
      const month = parsedDate.month();
      const year = parsedDate.year();
      date.year(year);
      date.month(month);
      date.date(day);

      setLineTabData((prev) => ({
        ...prev,
        [fieldKey]: date.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      }));
    },
    [firstDateComChange, startDate, endDate],
  );

  const onTimeChange = useCallback(
    (fieldKey, value) => {
      const isFirstInitialOnChange = firstDateComChange[`${fieldKey}Time`];
      if (isFirstInitialOnChange) {
        setFirstDateComChange((prev) => ({
          ...prev,
          [`${fieldKey}Time`]: false,
        }));
        return;
      }
      const [hours, minutes] = value?.split(':').map(Number) || [];
      const date =
        (fieldKey === 'orderStartDate' ? startDate : endDate) ||
        moment().utc().startOf('day');
      date.set({
        hour: hours,
        minute: minutes,
      });
      setLineTabData((prev) => ({
        ...prev,
        [fieldKey]: date.utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      }));
    },
    [firstDateComChange, startDate, endDate],
  );

  const onDirectChange = useCallback(
    (value) => {
      setLineTabData((prev) => ({
        ...prev,
        isDirect: value,
      }));
    },
    [isDirect],
  );

  return (
    <>
      <FlexView column className="margin-top-6">
        <div className="grid-row margin-bottom-6">
          <DatePicker
            id="open-date"
            name="openDate"
            defaultValue={formattedStartDate}
            className="margin-right-4"
            labelClass="text-bold margin-bottom-2"
            label="Open date"
            onChange={(value) => onDateChange('orderStartDate', value)}
            disabled={!canUpdateContract}
          />
          <TimePicker
            id="open-time"
            defaultValue={formattedStartTime}
            name="openTime"
            label="Open time"
            className="margin-right-4"
            onChange={(value) => onTimeChange('orderStartDate', value)}
            disabled={!canUpdateContract}
          />
          <DatePicker
            id="close-date"
            name="closeDate"
            defaultValue={formattedEndDate}
            labelClass="text-bold margin-bottom-2"
            className="margin-right-4"
            label="Closeout date"
            onChange={(value) => onDateChange('orderEndDate', value)}
            disabled={!canUpdateContract}
          />
          <TimePicker
            id="close-time"
            name="closeTime"
            defaultValue={formattedEndTime}
            label="Closeout time"
            onChange={(value) => onTimeChange('orderEndDate', value)}
            disabled={!canUpdateContract}
          />
        </div>
        <div className="width-card">
          <div className="text-bold">
            Direct <RequiredFieldIndicator />
          </div>
          <SelectDropdown
            id="isDirect"
            name="isDirect"
            options={[
              { value: null, label: '-Select-' },
              { value: 1, label: 'Yes' },
              { value: 0, label: 'No' },
            ]}
            value={isDirect}
            onChange={(e) => {
              onDirectChange(e.target.value);
            }} // Handle state change
            required
            disabled={!canUpdateContract}
          />
        </div>
      </FlexView>
    </>
  );
};

export default StartEndDateTimePicker;
