/* eslint-disable react/prop-types */
import React from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
  Spinner,
  FlexView,
  ErrorMessage,
  Alert,
  AFPTable,
} from '@gsa/afp-component-library';

import Breadcrumbs from '../../../widgets/breadcrumbs';
import { emDashUnicode } from '../../../utilities/constants';
import ToastMessage from '../../../components/Toast/toast';
import OverlaySpinner from '../../../components/overlay-spinner';
import LineHistoryActions from './line-history-actions';
import {
  ContractLineProvider,
  useContractLine,
} from '../line-template/provider/contract-line-provider';
import LineHeader from '../line-template/line-header';
import LineHistorySubrow from './line-history-subrow';
import { mockLineVersionRows } from './test-data';

const ContractLineHistoryView = () => {
  const { contractHeaderId } = useParams();
  const {
    contractLineId,
    contractLine,
    getContractLineQueryResult: query,
    loading,
    saving,
    alert,
    setAlert,
  } = useContractLine();

  const getChanges = (row) => {
    const { deltaValue } = row;

    const lineTabChanges = Object.keys(deltaValue?.lineTabData || {}).flatMap(
      (key) => {
        const updates = deltaValue.lineTabData[key];
        if (key === 'contractLineFinancialRef') {
          return Object.keys(updates || {}).map((k) => {
            return {
              changeType: 'lineTabData',
              equipmentCategory: '',
              ecCode: '',
              fieldName: k,
              previousValue: updates[k].oldValue,
              currentValue: updates[k].newValue,
            };
          });
        }
        return [
          {
            changeType: 'lineTabData',
            equipmentCategory: '',
            ecCode: '',
            fieldName: key,
            previousValue: updates.oldValue,
            currentValue: updates.newValue,
          },
        ];
      },
    );

    return lineTabChanges;
  };

  const processData = (data) => {
    return {
      contractVersion: data.contractHeaderVersion.versionNumber,
      contractLineVersion: data.versionNumber,
      modTag: data.modTag,
      changedBy: data.changedBy,
      changedAt: data.changedAt,
      changes: getChanges(data),
    };
  };
  const rows = mockLineVersionRows.map(processData);

  const solicitaion = contractLine?.contractHeader?.solicitation;
  const purchaseTypeCode = solicitaion?.purchaseTypeCode;

  const breadcrumbs = (
    <Breadcrumbs
      path={[
        {
          location: `${window.AFP_CONFIG.appURLs.home}/home`,
          label: 'Home',
        },
        {
          location: `/catalog/contract`,
          label: 'Contracts',
        },
        {
          location: `/catalog/contract/contract-header/${contractHeaderId}/lines`,
          label: 'Contract line listing',
        },
        {
          location: `/catalog/contract/${contractHeaderId}/contract-line-template/${contractLineId}`,
          label: `Line item ${contractLine?.scheduleLine || ''}`,
        },
      ]}
      current="History"
    />
  );

  if (query?.called && !query?.loading && !contractLine) {
    return (
      <>
        {breadcrumbs}
        <Alert type="error">Contract line {contractLineId} is not found</Alert>
      </>
    );
  }

  if (loading) {
    return (
      <>
        {breadcrumbs}
        <Spinner />
      </>
    );
  }

  const isNonStandard = purchaseTypeCode === 'N';
  const isMas = purchaseTypeCode === 'M';
  let subHeader = null;
  const showManufacturerNumber =
    (isNonStandard && contractLine?.manufacturerModelNumber) || isMas;

  if (showManufacturerNumber) {
    subHeader = (
      <div className="title-m">
        <span className=" title-m-bold">Manufacturer model number: </span>
        {contractLine?.manufacturerModelNumber || emDashUnicode}
      </div>
    );
  } else {
    subHeader = (
      <>
        <div className="margin-right-2 title-m">
          <span className=" title-m-bold">Body: </span>
          {contractLine?.make?.makeName || emDashUnicode}{' '}
          {contractLine?.model?.modelName || emDashUnicode}
        </div>
        {contractLine?.chassisMake && (
          <div className="title-m">
            <span className=" title-m-bold">Chassis: </span>
            {contractLine?.chassisMake?.makeName || emDashUnicode}{' '}
            {contractLine?.chassisModel?.modelName || emDashUnicode}
          </div>
        )}
      </>
    );
  }

  return (
    <FlexView column>
      <FlexView>
        {loading && <Spinner data-testid="contract-line-spinner" />}
        {query?.error && (
          <ErrorMessage>
            An error occurred while loading the contract line data.
          </ErrorMessage>
        )}
      </FlexView>

      {saving && <OverlaySpinner />}

      {contractLine && (
        <>
          {breadcrumbs}
          {alert && (
            <ToastMessage
              type={alert.type}
              message={alert.message}
              onClose={() => setAlert(null)}
              closable
              className="margin-bottom-2"
            />
          )}

          <FlexView
            vAlignContent="bottom"
            grow
            className="margin-top-2 margin-bottom-2"
          >
            <div className="margin-bottom-2">
              <FlexView vAlignContent="center" className="margin-bottom-1">
                <div className="title-xl">
                  Line Item {contractLine?.scheduleLine}
                </div>
              </FlexView>
              <FlexView className="margin-top-2">{subHeader}</FlexView>
            </div>
            <FlexView grow> </FlexView>
            <LineHistoryActions />
          </FlexView>

          <LineHeader />

          <div className="title-m-bold margin-top-4 margin-bottom-2">
            Contract line history
          </div>
          <AFPTable
            data={rows || []}
            expandable
            columns={[
              {
                Header: 'Contract version',
                accessor: 'contractVersion',
                sortable: true,
                Cell: ({ value }) => {
                  return (
                    <RouterLink
                      to={`/catalog/contract/contract-header/${contractHeaderId}/history/${value}`}
                      className="text-bold margin-left-1"
                    >
                      {value}
                    </RouterLink>
                  );
                },
              },
              {
                Header: 'Contract line version',
                accessor: 'contractLineVersion',
                sortable: true,
                Cell: ({ value }) => {
                  return (
                    <RouterLink to="" className="text-bold margin-left-1">
                      {value}
                    </RouterLink>
                  );
                },
              },
              {
                Header: 'Modification number',
                accessor: 'modTag',
                sortable: true,
                Cell: ({ value }) => value || emDashUnicode,
              },
              {
                Header: 'Changed by',
                accessor: 'changedBy',
                sortable: true,
              },
              {
                Header: 'Date/time of change',
                accessor: 'changedAt',
                sortable: true,
              },
              {
                Header: 'Number of changes',
                accessor: 'changes',
                sortable: false,
                Cell: ({ value }) => {
                  return <span>{value.length}</span>;
                },
              },
            ]}
            renderRowSubComponent={({ row }) => {
              return <LineHistorySubrow changes={row.original.changes} />;
            }}
          />
        </>
      )}
    </FlexView>
  );
};

const ContractLineHistory = () => {
  return (
    <ContractLineProvider>
      <ContractLineHistoryView />
    </ContractLineProvider>
  );
};

export default ContractLineHistory;
