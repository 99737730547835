/* eslint-disable react/prop-types */
import React from 'react';
import { AFPTable } from '@gsa/afp-component-library';

import './line-history-subrow.scss';
import { emDashUnicode } from '../../../utilities/constants';

const lineTabFieldNamesMap = {
  isDirect: 'Direct flag',
  makeName: 'Body make name',
  modelName: 'Body model name',
  modelYear: 'Model year',
  chassisMakeName: 'Chassis make name',
  chassisModelName: 'Chassis model name',
  quantity: 'Quantity',
  msrp: 'MSRP price',
  unitPrice: 'Unit price',
  invoicePrice: 'Invoice price',
  orderStartDate: 'Order start date',
  orderEndDate: 'Order end date',
};

const tinyToYesNo = (v) => (v === 1 ? 'Yes' : 'No');

const LineHistorySubrow = ({ changes }) => {
  return (
    <div className="line-history-subrow">
      <div className="title-s-caps text-primary margin-top-2">
        Contract line version changes
      </div>
      <AFPTable
        data={changes || []}
        columns={[
          {
            Header: 'Change type',
            accessor: 'changeType',
            sortable: false,
            Cell: ({ value }) => {
              const titlesMap = {
                lineTabData: 'Contract line',
              };
              return titlesMap[value];
            },
          },
          {
            Header: 'Equipment category',
            accessor: 'equipmentCategory',
            sortable: false,
          },
          {
            Header: 'Equipment code',
            accessor: 'ecCode',
            sortable: false,
          },
          {
            Header: 'Field name',
            accessor: 'fieldName',
            sortable: false,
            Cell: ({ value }) => {
              return <span>{lineTabFieldNamesMap[value]}</span>;
            },
          },
          {
            Header: 'Previous value',
            accessor: 'previousValue',
            sortable: false,
            Cell: ({ value, row }) => {
              if (row.original.fieldName === 'isDirect') {
                return tinyToYesNo(parseInt(value, 10));
              }
              return value || emDashUnicode;
            },
          },
          {
            Header: 'Current value',
            accessor: 'currentValue',
            sortable: false,
            Cell: ({ value, row }) => {
              if (row.original.fieldName === 'isDirect') {
                return tinyToYesNo(parseInt(value, 10));
              }
              return value || emDashUnicode;
            },
          },
        ]}
      />
    </div>
  );
};

export default LineHistorySubrow;
