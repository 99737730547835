import React from 'react';
import { Link, FlexView } from '@gsa/afp-component-library';

import { emDashUnicode } from '../../../utilities/constants';
import { useContractLine } from './provider/contract-line-provider';
import { getContractPeriod } from '../components/contract-helpers';

const LineHeader = () => {
  const { contractLine } = useContractLine();

  const vendor = contractLine?.contractHeader?.vendor;
  const solicitation = contractLine?.contractHeader?.solicitation;
  const contractPeriod = getContractPeriod(
    solicitation?.solicitationPeriods,
    contractLine?.contractHeader?.solicitationPeriodId,
  );

  return (
    contractLine && (
      <FlexView column className="padding-2 bg-base-lightest">
        <div className="grid-row grid-gap-4 margin-bottom-4">
          <div className="grid-col flex-1">
            <div className="body-bold">Internal contract number</div>
            <div>
              {contractLine?.contractHeader?.contractNumber || emDashUnicode}{' '}
            </div>
          </div>

          <div className="grid-col flex-1">
            <div className="body-bold">Contract uPIID</div>
            <div>
              {contractLine?.contractHeader?.contractUpiid || emDashUnicode}{' '}
            </div>
          </div>

          <div className="grid-col flex-1">
            <div className="body-bold">Contractor name</div>
            <Link
              href={`${window.AFP_CONFIG.appURLs.home}/vendor/details/${vendor?.id}`}
            >
              {vendor?.vendorName}
            </Link>
          </div>
          <div className="grid-col flex-1">
            <div className="body-bold">Contractor UEI</div>
            <div>{vendor?.uniqueEntityIdentifier || emDashUnicode}</div>
          </div>

          <div className="grid-col flex-1">
            <div className="body-bold">Model year</div>
            <div>
              {!contractLine?.modelYear ||
              parseInt(contractLine?.modelYear, 10) === 1901
                ? emDashUnicode
                : contractLine?.modelYear}
            </div>
          </div>

          <div className="grid-col flex-1">
            <div className="body-bold">Publish</div>
            <div>
              {contractLine?.contractHeader?.publishedInFleet ? 'Yes' : 'No'}
            </div>
          </div>
        </div>

        <div className="grid-row grid-gap-4">
          <div className="grid-col flex-1">
            <div className="body-bold">Standard item</div>
            <div>
              {`${contractLine?.scheduleLine}, ${contractLine?.standardItem?.title}` ||
                emDashUnicode}
            </div>
          </div>

          <div className="grid-col flex-1">
            <div className="body-bold">Solicitation uPIID</div>
            <div>{solicitation?.solicitationNumber || emDashUnicode}</div>
          </div>

          <div className="grid-col flex-1">
            <div className="body-bold">Awarded in GSA Fleet</div>
            <div>
              {contractLine?.contractHeader?.fleetAwarded ? 'Yes' : 'No'}
            </div>
          </div>

          <div className="grid-col flex-1">
            <div className="body-bold">Federal vehicle standards year</div>
            <div>{contractLine?.standardItem?.year || emDashUnicode}</div>
          </div>

          <div className="grid-col flex-1">
            <div className="body-bold">Period</div>
            <div>{contractPeriod}</div>
          </div>
          <div className="grid-col flex-1" />
        </div>
      </FlexView>
    )
  );
};

export default LineHeader;
