import { GET_CONTRACT_HEADER_BY_ID } from '../../contract-header/contract-header.gql';

export const mockHeaderVersions = [
  {
    id: 20,
    contractHeaderId: 1000,
    deltaValue: { solicitationPeriodId: { current: 2, previous: 1 } },
    versionNumber: 4,
    createdBy: '434ac961-a53d-43dc-b1bc-c17e308298cc',
    createdByUser: { fullName: 'Test User 2', email: 'test.user@gsa.gov' },
    createdAt: '2024-09-09 20:04:05',
    updatedBy: null,
    updatedAt: '2024-09-09 20:04:05',
  },
  {
    id: 19,
    contractHeaderId: 1000,
    deltaValue: { solicitationPeriodId: { current: 2, previous: 1 } },
    versionNumber: 3,
    createdBy: '434ac961-a53d-43dc-b1bc-c17e308298cc',
    createdByUser: { fullName: 'Test User 1', email: 'test.user@gsa.gov' },
    createdAt: '2024-09-08T20:04:05Z',
    updatedBy: null,
    updatedAt: '2024-09-08T20:04:05Z',
  },
  {
    id: 18,
    contractHeaderId: 1000,
    deltaValue: {
      solicitationPeriodId: { current: 2, previous: 1 },
      contractEndDate: {
        current: '2025-09-01 20:04:05',
        previous: '2025-06-01 20:04:05',
      },
    },
    versionNumber: 2,
    createdBy: '434ac961-a53d-43dc-b1bc-c17e308298cc',
    createdByUser: { fullName: 'Test User 3', email: 'test.user@gsa.gov' },
    createdAt: '2024-08-09T20:04:05Z',
    updatedBy: null,
    updatedAt: '2024-08-09T20:04:05Z',
  },
  {
    id: 17,
    contractHeaderId: 1000,
    deltaValue: {
      contractYear: { current: 2025, previous: 2024 },
      updateField: { current: 2025, previous: 2024 },
    },
    versionNumber: 1,
    createdBy: '434ac961-a53d-43dc-b1bc-c17e308298cc',
    createdByUser: { fullName: 'Test User 4', email: 'test.user@gsa.gov' },
    createdAt: '2024-07-09T20:04:05Z',
    updatedBy: null,
    updatedAt: '2024-07-09T20:04:05Z',
  },
  {
    id: 16,
    contractHeaderId: 1000,
    deltaValue: null,
    versionNumber: 0,
    createdBy: '434ac961-a53d-43dc-b1bc-c17e308298cc',
    createdByUser: { fullName: 'Test User 2', email: 'test.user@gsa.gov' },
    createdAt: '2024-06-06T20:04:05Z',
    updatedBy: null,
    updatedAt: '2024-06-06T20:04:05Z',
  },
];

export const mockContractHeader = {
  contractHeaderId: '1000',
  contractNumber: '47QMCA20D000A',
  solicitationNumber: '47QMCA20D000A',
  buyerCode: null,
  contractStartDate: null,
  contractEndDate: null,
  contractTerminationDate: null,
  dollarValueMaximumOrder: null,
  contractSpecialistName: null,
  contractSpecialistEmail: null,
  formalContractNumber: null,
  contractingOfficerName: null,
  contractingOfficerEmail: null,
  solicitationId: 1,
  solicitationPeriodId: 2,
  contractUpiid: null,
  vendorId: '000b5808-4a62-4380-9214-a3758d669fc1',
  contractYear: 2024,
  fleetAwarded: true,
  awardedDate: null,
  awardCancelledDate: null,
  pocName: null,
  pocEmail: null,
  pocPhone: null,
  parentContractNumber: null,
  contractIndicator: null,
  isActive: true,
  status: 'New',
  publishedInFleet: null,
  updatedBy: '434ac961-a53d-43dc-b1bc-c17e308298cc',
  updatedAt: '2024-09-11T10:22:31.000Z',
  vendor: {
    id: '000b5808-4a62-4380-9214-a3758d669fc1',
    vendorName: 'ALASKA TRUCK CENTER (DBA)',
    uniqueEntityIdentifier: 'testuei',
    fleetVendorNo: 'F314238458',
    fleetVendorNumber: '',
    fleetStatus: null,
    registrationExpirationDate: null,
    vendorOrderTransmittals: null,
    __typename: 'VendorModel',
  },
  solicitation: {
    purchaseTypeCode: 'S',
    solicitationNumber: 'A3067376NXYZ',
    solicitationNumberLegacy: 'A00001',
    solicitationPeriods: [
      {
        id: '1',
        startDate: '2024-01-01T05:00:00.000Z',
        endDate: '2024-05-01T00:00:00.000Z',
        periodType: 'O',
        __typename: 'SolicitationPeriod',
      },
      {
        id: '2',
        startDate: '2024-05-01T04:00:00.000Z',
        endDate: '2024-06-01T00:00:00.000Z',
        periodType: 'M',
        __typename: 'SolicitationPeriod',
      },
    ],
    programs: [
      {
        id: '2',
        program: 'BUS',
        solicitationLines: [
          {
            id: '2',
            standardItem: {
              id: '2446',
              standardItemNumber: '210',
              status: 'Archived',
              title: 'AMBULANCE, TYPE I-AD, CONV. CAB-CHASIS, ADDITIONAL DUTY',
              tags: {
                value: ['REQ_CHASSIS_MOD'],
              },
              __typename: 'StandardItem',
            },
            __typename: 'SolicitationLine',
          },
        ],
        __typename: 'SolicitationProgram',
      },
      {
        id: '1',
        program: 'LPW',
        solicitationLines: [
          {
            id: '1',
            standardItem: {
              id: '15625',
              standardItemNumber: '513C',
              status: 'Archived',
              title: '4x2 CAB AND CHASSIS, CABOVER, 33000 LBS GVWR',
              tags: {
                value: ['REQ_CHASSIS_MOD'],
              },
              __typename: 'StandardItem',
            },
            __typename: 'SolicitationLine',
          },
        ],
        __typename: 'SolicitationProgram',
      },
    ],
    __typename: 'Solicitation',
  },
  __typename: 'ContractHeader',
};

export const mockGetContractHeader = {
  request: {
    query: GET_CONTRACT_HEADER_BY_ID,
    variables: {
      contractHeaderId: '1000',
    },
  },
  result: {
    data: {
      getContractHeaderById: mockContractHeader,
    },
  },
};
