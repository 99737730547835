import React, { useMemo } from 'react';
import { AFPTable, EmptyState } from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useContractLine } from '../provider/contract-line-provider';
import { getEngineColumns, EngineExpandedRow } from './table-defs-engine';
import {
  getFuelInputMap,
  isEngineDataReady,
  DC_FAST_CHARGE_TYPES,
  FAST_CHARGE_TYPE,
  compareValue,
  getOriginalValue,
  getOriginalOldValue,
} from '../helpers/ec-helpers';
import { OPERATIONS, SUBJECTS } from '../../../../utilities/constants';

const EngineAndFuel = () => {
  const {
    isAwardedInFleet,
    fuelTypes,
    engineTable,
    setEngine,
  } = useContractLine();

  const ability = useAppAbility();
  const canUpdateContract = ability.can(OPERATIONS.Update, SUBJECTS.Contract);

  const tableData = engineTable.filter(({ isDeleted }) => !isDeleted);

  const resetField = (field, value = '') => ({ ...field, newValue: value });

  const resetFcTypes = (charging) => {
    const result = { ...charging.fcTypes };
    DC_FAST_CHARGE_TYPES.forEach((fcType) => {
      result[fcType].checked.newValue = false;
      result[fcType].chargeOption.newValue = '';
      result[fcType].userEnteredType.newValue = '';
    });
    return result;
  };

  const resetCharging = (charging) => ({
    typeInput: resetField(charging.typeInput, []),
    isFastChargeCapableInput: resetField(charging.isFastChargeCapableInput),
    fcTypes: resetFcTypes(charging),
    bkwhInput: resetField(charging.bkwhInput),
  });
  const resetMpg = (mpg) => ({
    cityInput: resetField(mpg.cityInput),
    highwayInput: resetField(mpg.highwayInput),
    combinedInput: resetField(mpg.combinedInput),
    gpmiInput: resetField(mpg.gpmiInput),
  });

  const updateChargingBlock = (original, fields, value) => {
    const [subField, fcType, fcTypeField] = fields;
    const charging = { ...original.charging };
    if (subField === 'typeInput' && value.includes(FAST_CHARGE_TYPE)) {
      // set fast charge capable if includes fast charge type
      charging.typeInput.newValue = value;
      charging.isFastChargeCapableInput.newValue = 'Y';
    } else if (subField === 'isFastChargeCapableInput' && value !== 'Y') {
      // reset fast charge types if is not capable
      charging.isFastChargeCapableInput.newValue = value;
      charging.fcTypes = resetFcTypes(charging);
    } else if (subField === 'fcTypes') {
      charging.fcTypes[fcType][fcTypeField].newValue = value;
      if (fcTypeField === 'checked' && !value) {
        // clear fast charge type field if unchecked
        charging.fcTypes[fcType].userEnteredType.newValue = '';
        charging.fcTypes[fcType].chargeOption.newValue = '';
      }
    } else {
      charging[subField].newValue = value;
    }
    return charging;
  };

  const updateEngineData = (original, fieldName, value) => {
    const valueObj = {
      oldValue: getOriginalOldValue(original, fieldName),
      newValue: value,
    };
    const fields = fieldName.split('.');
    let data; // form data update
    let table; // table UI update
    if (fields[0] === 'fuelTypeCodeInput') {
      // update fuel type map and clear fields not in the map
      const fuelMap = getFuelInputMap(value, fuelTypes);
      const resetFields = {
        ...(Object.keys(fuelMap).length > 0 || {
          isFuelDataUnknown: resetField(original.isFuelDataUnknown, false),
        }),
        ...(fuelMap.displacement || {
          cylindersInput: resetField(original.cylindersInput),
          engineLitresInput: resetField(original.engineLitresInput),
        }),
        ...(fuelMap.range || {
          rangeInput: resetField(original.rangeInput),
        }),
        ...(fuelMap.rangeE || {
          rangeElectricInput: resetField(original.rangeElectricInput),
        }),
        ...(fuelMap.charging || { charging: resetCharging(original.charging) }),
        ...(fuelMap.mpgC || { mpgC: resetMpg(original.mpgC) }),
        ...(fuelMap.mpgA || { mpgA: resetMpg(original.mpgA) }),
        ...(fuelMap.mpgE || { mpgE: resetMpg(original.mpgE) }),
      };
      data = {
        fuelTypeCodeInput: valueObj,
        fuelInputMap: fuelMap,
        ...resetFields,
      };
      if (value) table = { isExpanded: true };
    } else if (fields[0] === 'charging') {
      data = {
        charging: updateChargingBlock(original, fields.slice(1), value),
      };
    } else if (fields[0] === 'deliveryRegionType' && value !== 'Other') {
      // clear states if region is not 'Other'
      data = {
        deliveryRegionType: valueObj,
        deliveryStatesInput: { ...original.deliveryStatesInput, newValue: [] },
      };
    } else {
      data = fields[1]
        ? { [fields[0]]: { ...original[fields[0]], [fields[1]]: valueObj } }
        : { [fields[0]]: valueObj };
    }

    // update ready state
    data.ready = isEngineDataReady({ ...original, ...data }, isAwardedInFleet);
    return { data, table };
  };

  const onUpdate = async (original, fieldName, value) => {
    if (compareValue(getOriginalValue(original, fieldName), value)) return; // no change
    const updates = updateEngineData(original, fieldName, value);
    setEngine(original.id, updates);
  };

  const columns = useMemo(
    () => getEngineColumns({ onUpdate, readOnly: !canUpdateContract }),
    [onUpdate, canUpdateContract],
  );
  const subrow = useMemo(
    // eslint-disable-next-line
    () => ({ row }) => (
      <EngineExpandedRow
        row={row}
        onUpdate={onUpdate}
        isReadOnly={!canUpdateContract}
      />
    ),
    [onUpdate],
  );

  return (
    <div className="margin-bottom-8 contract-line-template">
      <div className="title-m-bold">Engine and fuel</div>
      <AFPTable
        fullWidth
        columns={columns}
        data={tableData}
        renderRowSubComponent={subrow}
      />
      {!tableData.length && (
        <div className="table-empty-state">
          <EmptyState bottomText="Engine and fuel not available" />
        </div>
      )}
    </div>
  );
};

export default EngineAndFuel;
