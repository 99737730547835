import { emDashUnicode } from '../../../utilities/constants';

export const formatDateTime = (value) => {
  if (!value) return emDashUnicode;
  try {
    return Intl.DateTimeFormat('en-US', {
      dateStyle: 'short',
      timeStyle: 'short',
    }).format(new Date(value));
  } catch {
    return emDashUnicode;
  }
};

export const HeaderVersionFieldList = [
  { key: 'contractYear', label: 'Contract year' },
  { key: 'solicitationPeriodId', label: 'Solicitation period ID' },
  { key: 'ueiContractor', label: 'Contractor UEI' },
  {
    key: 'awardCancelledDate',
    label: 'Award cancelled date',
    converter: formatDateTime,
  },
  {
    key: 'contractEndDate',
    label: 'Contract end date',
    converter: formatDateTime,
  },
  { key: 'contractUnits', label: 'Contract units' },
  { key: 'dollarValueMaximumOrder', label: 'Maximum dollar value' },
];

const getFieldValue = (value, converter) => {
  if (value == null) return emDashUnicode;
  return converter ? converter(value) : value;
};

export const processHeaderVersionDelta = (deltaValue) => {
  const changeList = [];
  // eslint-disable-next-line
  for (const field of HeaderVersionFieldList) {
    if (deltaValue?.[field.key])
      changeList.push({
        fieldName: field.label,
        previousValue: getFieldValue(
          deltaValue[field.key].previous,
          field.converter,
        ),
        currentValue: getFieldValue(
          deltaValue[field.key].current,
          field.converter,
        ),
      });
  }
  return changeList;
};

export const processHeaderVersions = (versions) => {
  return versions.map(
    ({ versionNumber, deltaValue, createdAt, createdByUser }) => {
      const changes = processHeaderVersionDelta(deltaValue);
      return {
        versionNumber,
        changedBy: createdByUser?.fullName,
        changedByEmail: createdByUser?.email,
        changedAt: createdAt,
        changes,
        changeCount: changes.length,
      };
    },
  );
};

export const filterAndSortVersions = (
  contractHeaderVersions,
  filters,
  order,
) => {
  const getDateStr = (str) => str.substring(0, 10);
  const field = order[0];
  const asc = order[1] === 'ASC' ? -1 : 1;
  return contractHeaderVersions
    .filter(
      (v) =>
        (!filters.versionNumber ||
          v.versionNumber === +filters.versionNumber) &&
        (!filters.fieldName ||
          v.changes.some((c) => c.fieldName === filters.fieldName)) &&
        (!filters.changedBy || v.changedBy === filters.changedBy) &&
        (!filters.changedAtFrom ||
          Number.isNaN(Date.parse(filters.changedAtFrom)) ||
          getDateStr(v.changedAt) >=
            getDateStr(new Date(filters.changedAtFrom).toISOString())) &&
        (!filters.changedAtTo ||
          Number.isNaN(Date.parse(filters.changedAtTo)) ||
          getDateStr(v.changedAt) <=
            getDateStr(new Date(filters.changedAtTo).toISOString())),
    )
    .sort((a, b) => (a[field] < b[field] ? asc : -asc));
};

export const getFilterOptions = (allVersions, field) => {
  const values = new Set();
  allVersions.forEach((v) => {
    if (field === 'versionNumber' || field === 'changedBy')
      values.add(v[field]);
    else if (field === 'fieldName')
      v.changes.forEach((c) => values.add(c.fieldName));
  });
  return [
    { label: '-Select-', value: '' },
    ...[...values].sort().map((v) => ({ label: v, value: v })),
  ];
};
