import React from 'react';
import {
  Button,
  RequiredFieldIndicator,
  useModal,
  StatusBadge,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import {
  OPERATIONS,
  SUBJECTS,
  emDashUnicode,
  enDashUnicode,
} from '../../../../../utilities/constants';
import { useContractLine } from '../../provider/contract-line-provider';
import { getMakeModelStatusBadgeConfig } from '../../../../bid-dashboard/helpers';
import { SIMakeModelPanellProvider } from '../../../contract-line/make-model/si-make-model-panel-provider';
import SelectMakeModelModal from './select-make-model-modal';

const LineMakeModelSection = () => {
  const {
    contractLine,
    lineTabData,
    setLineTabData,
    requiresChassis,
  } = useContractLine();

  const { isOpen, openModal, closeModal } = useModal();
  const ability = useAppAbility();
  const canUpdateContract = ability.can(OPERATIONS.Update, SUBJECTS.Contract);

  const genLabel = (label, required) => (
    <span className="text-bold" data-testid={label}>
      {label} {required && <RequiredFieldIndicator />}
    </span>
  );

  const makeName =
    lineTabData?.make?.makeName ||
    contractLine?.make?.makeName ||
    enDashUnicode;
  const modelName =
    lineTabData?.model?.modelName ||
    contractLine?.model?.modelName ||
    enDashUnicode;
  const chassisMakeName =
    lineTabData?.chassisMake?.makeName ||
    contractLine?.chassisMake?.makeName ||
    enDashUnicode;
  const chassisModelName =
    lineTabData?.chassisModel?.modelName ||
    contractLine?.chassisModel?.modelName ||
    enDashUnicode;
  const modelYear =
    lineTabData?.modelYear || contractLine?.modelYear || enDashUnicode;
  const badge = getMakeModelStatusBadgeConfig(
    lineTabData?.makeModelApproval !== undefined
      ? lineTabData?.makeModelApproval
      : contractLine?.makeModelApproval,
  );

  const solicitaion = contractLine?.contractHeader?.solicitation;
  const purchaseTypeCode = solicitaion?.purchaseTypeCode;
  const isNonStandard = purchaseTypeCode === 'N';
  const isMas = purchaseTypeCode === 'M';

  const manufacturerModelNumber =
    lineTabData?.manufacturerModelNumber ||
    contractLine?.manufacturerModelNumber;
  const showManufacturerNumber =
    (isNonStandard && contractLine?.manufacturerModelNumber) || isMas;

  return (
    <div className="margin-top-2 margin-bottom-4">
      <div className="grid-row grid-gap-lg">
        <div className="grid-col flex-1">
          {genLabel('Standard item', false)}
          <div className="padding-top-1">
            {contractLine?.standardItem?.standardItemNumber || enDashUnicode}{' '}
          </div>
        </div>

        {showManufacturerNumber && (
          <>
            <div className="grid-col flex-1">
              {genLabel('Manufacturer model number', false)}
              <div className="padding-top-1">
                {manufacturerModelNumber || enDashUnicode}
              </div>
            </div>
            <div className="grid-col flex-1">
              {genLabel('Model year', false)}
              <div className="padding-top-1">
                {parseInt(modelYear, 10) === 1901 ? emDashUnicode : modelYear}
              </div>
            </div>
          </>
        )}
        {!showManufacturerNumber && (
          <>
            <div className="grid-col flex-1">
              {genLabel('Body Make and Model', false)}
              <div className="padding-top-1">
                {makeName} {modelName}
              </div>
            </div>
            {requiresChassis && (
              <div className="grid-col flex-1">
                {genLabel('Chassis Make and Model', false)}
                <div className="padding-top-1">
                  {chassisMakeName} {chassisModelName}
                </div>
              </div>
            )}
            <div className="grid-col flex-1">
              {genLabel('Model year', false)}
              <div className="padding-top-1">
                {parseInt(modelYear, 10) === 1901 ? emDashUnicode : modelYear}
              </div>
            </div>
            <div className="grid-col flex-1">
              {genLabel('Status', false)}
              <div className="padding-top-1">
                {badge ? (
                  <StatusBadge variant={badge.variant}>
                    {badge.label}
                  </StatusBadge>
                ) : (
                  <StatusBadge variant="Warning-Gray">
                    Pending review
                  </StatusBadge>
                )}
              </div>
            </div>
          </>
        )}
        {canUpdateContract && (
          <div className="grid-col flex-1 padding-top-1">
            <Button
              className="flex-justify-self-end"
              variant="outline"
              label="Edit line item"
              onClick={openModal}
            />
          </div>
        )}
      </div>

      {isOpen && (
        <SIMakeModelPanellProvider
          initialState={{
            bodyMake: lineTabData?.make || contractLine?.make,
            bodyModel: lineTabData?.model || contractLine?.model,
            chassisMake: lineTabData?.chassisMake || contractLine?.chassisMake,
            chassisModel:
              lineTabData?.chassisModel || contractLine?.chassisModel,
            modelYear: lineTabData?.modelYear || contractLine?.modelYear,
            standardItem: contractLine?.standardItem,
          }}
        >
          <SelectMakeModelModal
            showManufacturerNumber={showManufacturerNumber}
            data={contractLine}
            onClose={closeModal}
            onSelect={(data) => {
              setLineTabData((prev) => {
                return {
                  ...prev,
                  ...data,
                };
              });
              closeModal();
            }}
          />
        </SIMakeModelPanellProvider>
      )}
    </div>
  );
};

export default LineMakeModelSection;
