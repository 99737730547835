import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  ButtonDropdown,
  ButtonDropdownItem,
  FlexView,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const ContractLineActionsPannel = ({ contractHeaderId }) => {
  const { contractLineId } = useParams();
  const history = useHistory();
  const actionsPanelLinks = (handler) => [
    {
      title: 'View contract line history',
      onClickHandler: handler?.viewContractHistory,
      customIcon: true,
      icon: 'visibility',
    },
    {
      title: 'View contract header',
      onClickHandler: handler?.viewContractHeader,
      customIcon: true,
      icon: 'visibility',
    },
  ];

  // TODO: update links
  const actionHandlers = {
    viewContractHistory: () => {
      history.push(
        `/catalog/contract/${contractHeaderId}/contract-line-template/${contractLineId}/history`,
      );
    },
    viewContractHeader: () => {
      history.push(`/catalog/contract/contract-header/${contractHeaderId}`);
    },
  };

  return (
    <FlexView hAlignContent="right">
      <ButtonDropdown
        className="margin-right-0 margin-bottom-1"
        label="Actions"
        variant="outline"
        iconSize={3}
        side="right"
      >
        {actionsPanelLinks(actionHandlers).map((link) => (
          <React.Fragment key={link.title}>
            {link ? (
              <ButtonDropdownItem
                className="text-primary hover:bg-blue-5"
                label={link.title}
                iconName={link.icon}
                iconType={link.customIcon && 'custom'}
                iconFill="#005ea2"
                onClick={() => link.onClickHandler()}
              />
            ) : null}
          </React.Fragment>
        ))}
      </ButtonDropdown>
    </FlexView>
  );
};

ContractLineActionsPannel.propTypes = {
  contractHeaderId: PropTypes.string.isRequired,
};
export default ContractLineActionsPannel;
