/* eslint-disable import/prefer-default-export */
export const mockLineVersionRows = [
  {
    versionNumber: 0,
    contractHeaderVersion: {
      versionNumber: 1,
      __typename: 'ContractHeaderVersion',
    },
    createdAt: '2024-09-16T15:28:18.000Z',
    createdBy: '2053e500-1920-4519-ae84-337879282c76',
    modTag: null,
    deltaValue: null,
    __typename: 'ContractLineVersion',
  },
  {
    versionNumber: 1,
    contractHeaderVersion: {
      versionNumber: 1,
      __typename: 'ContractHeaderVersion',
    },
    createdAt: '2024-09-16T19:33:55.000Z',
    createdBy: '2053e500-1920-4519-ae84-337879282c76',
    modTag: null,
    deltaValue: {
      lineTabData: {
        isDirect: {
          newValue: '0',
          oldValue: '1',
        },
      },
    },
    __typename: 'ContractLineVersion',
  },
  {
    versionNumber: 2,
    contractHeaderVersion: {
      versionNumber: 1,
      __typename: 'ContractHeaderVersion',
    },
    createdAt: '2024-09-16T19:33:55.000Z',
    createdBy: '2053e500-1920-4519-ae84-337879282c76',
    modTag: null,
    deltaValue: {
      lineTabData: {
        isDirect: { newValue: '1', oldValue: '0' },
        makeName: { newValue: 'A & A MANUFACTURING' },
        quantity: { newValue: '264', oldValue: '26' },
        modelName: { newValue: 'A & A Manufacturing' },
        modelYear: { newValue: '2023', oldValue: 'null' },
        orderEndDate: {
          newValue: '2024-09-19T00:00:00.000Z',
          oldValue: '2024-09-16T00:00:00.000Z',
        },
        orderStartDate: {
          newValue: '2024-09-06T00:00:00.000Z',
          oldValue: '2024-09-03T00:00:00.000Z',
        },
        chassisMakeName: { newValue: 'A & A MANUFACTURING' },
        chassisModelName: { newValue: 'A & A Manufacturing' },
        contractLineFinancialRef: {
          msrp: { newValue: '69', oldValue: '7' },
          unitPrice: { newValue: '44', oldValue: '4' },
          invoicePrice: { newValue: '44', oldValue: '4' },
        },
      },
    },
  },
];
