/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AFPTable,
  Pagination,
  Spinner,
  AFPTableRowAction,
} from '@gsa/afp-component-library';
import ContractSearchTableExpandedRow from './contract-search-table-expanded-row';

import './contract-search-table.scss';

const DOCUMENT_ACTIONS = [
  {
    icon: 'visibility',
    label: 'View contract',
    action: 'viewcontract',
  },
  {
    icon: 'arrow_forward',
    label: 'View contract line listing',
    action: 'viewcontractlinelisting',
  },
  {
    icon: 'visibility',
    label: 'View contract history',
    action: 'viewcontracthistory',
  },
];

const ITEMS_PER_PAGE_DEFAULTS = [10, 20, 50];

const ContractSearchTable = ({
  loading,
  data,
  offset,
  limit,
  count,
  onPageChange,
  onSort,
}) => {
  const history = useHistory();

  const handleSelectAction = (action, row) => {
    // const contractId = row.original.id;
    const { contractHeaderId } = row.original;

    const normalizedAction = action.trim().toLowerCase().replace(/\s+/g, '');

    switch (normalizedAction) {
      case 'viewcontract':
        history.push(`/catalog/contract/contract-header/${contractHeaderId}`);
        break;
      case 'viewcontractlinelisting':
        history.push(
          `/catalog/contract/contract-header/${contractHeaderId}/lines`,
        );
        break;
      case 'viewcontracthistory':
        history.push(
          `/catalog/contract/contract-header/${contractHeaderId}/history`,
        );
        break;
      default:
        break;
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Contract uPIID',
        accessor: 'contractUpiid',
        sortable: false,
      },
      {
        Header: 'Contractor name',
        accessor: 'vendor.vendorName',
        sortable: false,
      },
      {
        Header: 'UEI',
        accessor: 'vendor.uniqueEntityIdentifier',
        sortable: false,
      },
      {
        Header: 'Contract year',
        accessor: 'contractYear',
      },
      {
        Header: 'Actions',
        sortable: false,
        Cell: ({ row }) => {
          return (
            <AFPTableRowAction
              row={row}
              actions={DOCUMENT_ACTIONS}
              onSelectAction={(action) => handleSelectAction(action, row)}
            />
          );
        },
      },
    ],
    [handleSelectAction],
  );

  const renderRowSubComponent = useCallback(({ row }) => {
    return <ContractSearchTableExpandedRow row={row} />;
  });

  if (loading)
    return (
      <div className="margin-y-8">
        <Spinner size="small" />
      </div>
    );

  if (!data.length) return null;

  return (
    <div id="contract-search-table" className="margin-top-4">
      <div className="text-primary text-bold text-uppercase">Contracts</div>
      <AFPTable
        expandable
        fullWidth
        columns={columns}
        data={data}
        onSort={onSort}
        renderRowSubComponent={renderRowSubComponent}
      />
      <div className="padding-y-4 pagination">
        <Pagination
          itemsPerPageOptions={ITEMS_PER_PAGE_DEFAULTS}
          onPageChange={onPageChange}
          variant="advanced"
          itemsPerPage={limit}
          currentPage={offset / limit + 1}
          itemsCount={count}
        />
      </div>
    </div>
  );
};

ContractSearchTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default ContractSearchTable;
