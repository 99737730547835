import React, { useState, useEffect, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  TextInput,
  RequiredFieldIndicator,
  Icon,
  FlexView,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import {
  GET_BID_LINE_PREVIOUS_PRICE,
  GET_PAST_YEAR_STANDARD_ITEM_IDS,
} from '../../../bid-dashboard/bid-line-details/provider/queries';
import {
  OPERATIONS,
  SUBJECTS,
  emDashUnicode,
  emSpaceUnicode,
  enDashUnicode,
} from '../../../../utilities/constants';
import { useContractLine } from '../provider/contract-line-provider';

export const formatPrice = (price, style, currency) => {
  return new Intl.NumberFormat('en-US', {
    style,
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  }).format(price);
};

const validationSchema = yup.object().shape({
  unitPrice: yup
    .number()
    .integer('Current price must be a whole number.')
    .transform((value) =>
      value === '' || Number.isNaN(value) ? undefined : value,
    )
    .min(1, 'Current price must be $1 or more.')
    .max(999999999, 'Current price must be less than $1,000,000,000')
    .required('Current price is required.'),
  invoicePrice: yup
    .number()
    .integer('Net Invoice price must be a whole number.')
    .transform((value) =>
      value === '' || Number.isNaN(value) ? undefined : value,
    )
    .min(1, 'Net Invoice price must be $1 or more.')
    .max(999999999, 'Net Invoice price must be less than $1,000,000,000')
    .required('Net Invoice price is required'),
  msrp: yup
    .number()
    .integer('Net MSRP must be a whole number.')
    .transform((value) =>
      value === '' || Number.isNaN(value) ? undefined : value,
    )
    .min(1, 'Net MSRP must be $1 or more.')
    .max(999999999, 'Net MSRP must be less than $1,000,000,000')
    .required('Net MSRP is required.'),
  asePrice: yup
    .number()
    .integer('AS&E unit Price must be a whole number.')
    .transform((value) =>
      value === '' || Number.isNaN(value) ? undefined : value,
    )
    .min(1, 'AS&E unit Price must be $1 or more.')
    .max(999999999, 'AS&E unit Price must be less than $1,000,000,000')
    .required('AS&E price is required.'),
  destinationCharge: yup
    .number()
    .integer('Destination charge must be a whole number.')
    .transform((value) =>
      value === '' || Number.isNaN(value) ? undefined : value,
    )
    .min(1, 'Destination charge must be $1 or more.')
    .test(
      'lessThanUnitPrice',
      'Destination charge must be less than the current price.',
      function lessThanUnitPrice(value) {
        if (value === undefined) return true;
        const unitPrice = parseInt(this.resolve(yup.ref('unitPrice')), 10);
        return value < unitPrice;
      },
    ),
});

const PriceInfoSection = () => {
  const [, setTotalPrice] = useState(0);
  const [prevPrice, setPrevPrice] = useState(null);
  const [standardItemIds, setStandardItemIds] = useState([]);
  const {
    lineTabData,
    contractLine,
    vendorData,
    setLineTabData,
  } = useContractLine();
  const hasSPLTag = contractLine?.standardItem?.tags?.value.some(
    (tag) => tag === 'SPL',
  );

  const ability = useAppAbility();
  const canUpdateContract = ability.can(OPERATIONS.Update, SUBJECTS.Contract);

  const { control, register, errors, watch } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      unitPrice:
        lineTabData?.contractLineFinancialRef?.unitPrice ||
        contractLine?.contractLineFinancialRef?.unitPrice,
      invoicePrice:
        lineTabData?.contractLineFinancialRef?.invoicePrice ||
        contractLine?.contractLineFinancialRef?.invoicePrice,
      msrp:
        lineTabData?.contractLineFinancialRef?.msrp ||
        contractLine?.contractLineFinancialRef?.msrp,
      asePrice:
        lineTabData?.contractLineFinancialRef?.asePrice ||
        contractLine?.contractLineFinancialRef?.asePrice,
      destinationCharge:
        lineTabData?.contractLineFinancialRef?.destinationCharge ||
        contractLine?.contractLineFinancialRef?.destinationCharge,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useQuery(GET_PAST_YEAR_STANDARD_ITEM_IDS, {
    variables: {
      sin: contractLine?.standardItem?.standardItemNumber,
    },
    onCompleted: (data) => {
      setStandardItemIds(data?.getPastYearStandardItemIdsBySINs);
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !contractLine?.standardItem?.standardItemNumber,
  });

  const { data: prevPriceData } = useQuery(GET_BID_LINE_PREVIOUS_PRICE, {
    variables: {
      vendorId: vendorData?.id,
      standardItemIds,
      modelYear: contractLine?.modelYear,
      ...(contractLine?.make?.makeCode && {
        makeCode: parseInt(contractLine?.make?.makeCode, 10),
      }),
      modelCode: contractLine?.model?.modelCode,
      ...(contractLine?.chassisMake?.makeCode && {
        chassisMakeCode: parseInt(contractLine?.chassisMake?.makeCode, 10),
      }),
      chassisModelCode: contractLine?.chassisModel?.modelCode,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !vendorData?.id || !standardItemIds?.length,
  });

  const genLabel = (label, required) => (
    <span className="text-bold" data-testid={label}>
      {label} {required && <RequiredFieldIndicator />}
    </span>
  );

  useEffect(() => {
    // eslint-disable-next-line
    contractLine &&
      setTotalPrice(
        formatPrice(
          contractLine?.unitPrice *
            +contractLine?.solicitationLine?.estimatedQty,
          'currency',
          'USD',
        ),
      );
  }, []);

  useEffect(() => {
    if (prevPriceData?.getPastContractPriceForSIN) {
      setPrevPrice(
        formatPrice(
          prevPriceData?.getPastContractPriceForSIN,
          'currency',
          'USD',
        ),
      );
    }
  }, [prevPriceData]);

  const handelOnChange = ({ target: { value, name } }) => {
    if (name === 'unitPrice') {
      setTotalPrice(
        formatPrice(
          value ? +value * +contractLine?.solicitationLine?.estimatedQty : 0,
          'currency',
          'USD',
        ),
      );
    }
  };

  const handleBlur = useCallback(
    (fieldName, value) => {
      setLineTabData((prev) => {
        const newValue = value ? parseFloat(value) : '';
        const currentValue =
          contractLine?.contractLineFinancialRef?.[fieldName] ||
          lineTabData?.contractLineFinancialRef?.[fieldName];
        if (newValue === currentValue) {
          return prev;
        }
        return {
          ...prev,
          contractLineFinancialRef: {
            ...prev.contractLineFinancialRef,
            [fieldName]: newValue,
          },
        };
      });
    },
    [contractLine, lineTabData],
  );

  const values = watch();

  const unitPrice = parseFloat(values.unitPrice);
  const invoicePrice = parseFloat(values.invoicePrice);
  const msrp = parseFloat(values.msrp);

  return (
    <>
      <form>
        <div className="grid-row margin-top-1  margin-bottom-2">
          <div className="grid-col flex-1 margin-top-3">
            <div className="body-bold" data-testid="privious price">
              {/* TODO: should be fetched from contract */}
              Previous price
            </div>
            <span> {prevPrice || emDashUnicode} </span>
          </div>
          <div className="grid-col flex-3">
            <div className="width-mobile">
              <Controller
                name="unitPrice"
                control={control}
                render={({ value, onChange, onBlur }) => (
                  <div>
                    <TextInput
                      name="unitPrice"
                      ref={register}
                      value={value === 0 ? emSpaceUnicode : value}
                      data-testid="current_price"
                      help="Current price must include base vehicle price and destination charge."
                      type="number"
                      min={0}
                      label={genLabel('Current price', true)}
                      prefix={<Icon iconName="attach_money" />}
                      errorMessage={
                        errors.unitPrice && errors.unitPrice.message
                      }
                      onChange={(e) => {
                        onChange(e);
                        handelOnChange(e);
                      }}
                      onBlur={(e) => {
                        if (!errors.unitPrice) {
                          onBlur(e);
                          handleBlur('unitPrice', e.target.value);
                        }
                      }}
                      disabled={!canUpdateContract}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        <FlexView column>
          <FlexView>
            <Controller
              name="invoicePrice"
              control={control}
              render={({ value, onChange, onBlur }) => (
                <div className="width-card">
                  <TextInput
                    name="invoicePrice"
                    ref={register}
                    value={value}
                    data-testid="net_price"
                    label={genLabel('Net Invoice price', true)}
                    type="number"
                    min={0}
                    prefix={<Icon iconName="attach_money" />}
                    errorMessage={
                      errors.invoicePrice && errors.invoicePrice.message
                    }
                    onChange={onChange}
                    onBlur={(e) => {
                      if (!errors.invoicePrice) {
                        onBlur(e);
                        handleBlur('invoicePrice', e.target.value);
                      }
                    }}
                    disabled={!canUpdateContract}
                  />
                </div>
              )}
            />
            <div className="margin-left-8 margin-top-3">
              <div className="text-bold margin-bottom-2">
                Current unit price vs. invoice - $ diff
              </div>
              <div>
                ${' '}
                {(invoicePrice && formatPrice(unitPrice - invoicePrice)) ||
                  enDashUnicode}
              </div>
            </div>
            <div className="margin-left-8 margin-top-3">
              <div className="text-bold margin-bottom-2">
                Current unit price vs. invoice - % diff
              </div>
              <div>
                {(invoicePrice &&
                  (
                    parseFloat((unitPrice - invoicePrice) / invoicePrice) * 100
                  ).toFixed(2)) ||
                  enDashUnicode}{' '}
                %
              </div>
            </div>
          </FlexView>
          <FlexView>
            <Controller
              name="msrp"
              control={control}
              render={({ value, onChange, onBlur }) => (
                <div className="width-card">
                  <TextInput
                    name="msrp"
                    ref={register}
                    value={value}
                    data-testid="msrp"
                    label={genLabel('Net MSRP', true)}
                    type="number"
                    min={0}
                    prefix={<Icon iconName="attach_money" />}
                    errorMessage={errors.msrp && errors.msrp.message}
                    onChange={onChange}
                    onBlur={(e) => {
                      if (!errors.msrp) {
                        onBlur(e);
                        handleBlur('msrp', e.target.value);
                      }
                    }}
                    disabled={!canUpdateContract}
                  />
                </div>
              )}
            />

            <div className="margin-left-8 margin-top-3">
              <div className="text-bold margin-bottom-2">
                Current unit price vs. MSRP - $ diff
              </div>
              <div>
                $ {(msrp && formatPrice(unitPrice - msrp)) || enDashUnicode}
              </div>
            </div>
            <div className="margin-left-8 margin-top-3">
              <div className="text-bold margin-bottom-2">
                Current unit price vs. MSRP - % diff
              </div>
              <div>
                {(msrp &&
                  (parseFloat((unitPrice - msrp) / msrp) * 100).toFixed(2)) ||
                  enDashUnicode}{' '}
                %
              </div>
            </div>
          </FlexView>
          {hasSPLTag && (
            <div className="grid-row flex-1">
              <div className="width-card">
                <Controller
                  name="asePrice"
                  control={control}
                  render={({ value, onChange, onBlur }) => (
                    <div className="width-card">
                      <TextInput
                        name="asePrice"
                        ref={register}
                        value={value}
                        data-testid="asePrice"
                        label={genLabel('AS&E unit price', true)}
                        type="number"
                        min={0}
                        prefix={<Icon iconName="attach_money" />}
                        errorMessage={
                          errors.asePrice && errors.asePrice.message
                        }
                        onChange={onChange}
                        onBlur={(e) => {
                          if (!errors.asePrice) {
                            onBlur(e);
                            handleBlur('asePrice', e.target.value);
                          }
                        }}
                        disabled={!canUpdateContract}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
          )}
        </FlexView>

        <div className="grid-row margin-top-4  margin-bottom-4">
          <div className="grid-col flex-3">
            <div className="width-mobile">
              <Controller
                name="destinationCharge"
                control={control}
                render={({ value, onChange, onBlur }) => (
                  <div className="width-mobile">
                    <TextInput
                      name="destinationCharge"
                      ref={register}
                      value={value}
                      help="Destination charge is included in current price but should be provided separately in this field for analysis."
                      data-testid="destinationCharge"
                      label={genLabel('Destination charge', false)}
                      type="number"
                      min={0}
                      prefix={<Icon iconName="attach_money" />}
                      errorMessage={
                        errors.destinationCharge &&
                        errors.destinationCharge.message
                      }
                      onChange={onChange}
                      onBlur={(e) => {
                        if (!errors.destinationCharge) {
                          onBlur(e);
                          handleBlur('destinationCharge', e.target.value);
                        }
                      }}
                      disabled={!canUpdateContract}
                    />
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default PriceInfoSection;
