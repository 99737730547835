import { gql } from '@apollo/client';

export const GET_APPLICABLE_YEARS = gql`
  query GetYears {
    getYearsForFVS {
      label
      value
      isDefault
    }
  }
`;

export const GET_FVS_OPTIONS_FOR_YEAR = gql`
  query GetFvsCodes($year: Float!, $includeOnlyCommentable: Boolean!) {
    getVehicleClassificationFVS(
      year: $year
      includeOnlyCommentable: $includeOnlyCommentable
    ) {
      id
      label
      value
      type
      uniqueKey
      currentYear
      parent
    }
  }
`;

export const GET_VEHICLES_STANDARDS = gql`
  query GetVehicleStandards(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getFederalStandards(
      offset: $offset
      limit: $limit
      order: $order
      filters: $filters
    ) {
      rows {
        id
        fedStandardCode
        fedStandard {
          code
          title
        }
        status
        year
        version
        commentingPeriods {
          id
          periodStart
          periodEnd
          isArchived
          userType
          status
        }
        statusHistory {
          id
          version
          status
          createdAt
          createdByUser
          initiatedByUser {
            fullName
          }
        }
      }
      count
      hasMore
    }
  }
`;

export const GET_STANDARDS_REL_DOCS = gql`
  query GetStandardsSupportingDocs(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getStandardsDocuments(
      offset: $offset
      limit: $limit
      order: $order
      filters: $filters
    ) {
      rows {
        id
        fvsId
        documentMetadataId
        docType
        metadata {
          name
          description
          docSource
          docStoreUri
          fileLocation
          fileMimeType
          scanStatus
          size
          uploadCompletedAt
        }
        createdAt
        createdByUser
        status
        effectiveDate
        uploadedBy {
          fullName
        }
      }
      count
      hasMore
    }
  }
`;

export const GET_STANDARDS_REL_TASKS = gql`
  query GetStandardsRelatedTasks(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getStandardsRelatedTasks(
      offset: $offset
      limit: $limit
      order: $order
      filters: $filters
    ) {
      rows {
        taskId
        taskIdStr
        assignor
        documentAuthor
        documentStatus
        taskInfo
        createdAt
        assignor
      }
      count
      hasMore
    }
  }
`;

export const CREATE_APPENDIX_DOC = gql`
  mutation CreateAppendixDoc($fedVehicleStandardsId: Float!) {
    createAppendixDocument(fedVehicleStandardsId: $fedVehicleStandardsId)
  }
`;

export const UPLOAD_FVS_DOC = gql`
  mutation UploadFvsDoc($input: FvsDocInput!) {
    uploadSupportingDoc(input: $input) {
      id
      documentMetadataId
      metadata {
        name
        description
        docSource
        docStoreUri
        fileLocation
        fileMimeType
        scanStatus
        size
        uploadCompletedAt
      }
      uploadedBy {
        fullName
      }
      signedUrl
    }
  }
`;

export const PUBLISH_FVS = gql`
  mutation PublishFvs(
    $fedVehicleStandardsId: Float!
    $effectiveDate: DateTime
  ) {
    publish(
      fedVehicleStandardsId: $fedVehicleStandardsId
      effectiveDate: $effectiveDate
    ) {
      status
    }
  }
`;

export const DELETE_FVS_DOC = gql`
  mutation DeleteFvsDocument($id: Float!) {
    deleteSupportingDocument(id: $id)
  }
`;

export const CREATE_COMMENTING_PERIOD = gql`
  mutation saveCommentingPeriod($input: [FvsCommentInput!]!) {
    addOrUpdateCommentingPeriods(input: $input)
  }
`;

export const COUNT_OPEN_COMMENTING_PERIODS = gql`
  query GetCountsOfCommentingPeriod {
    getCountOpenCommentingPeriods {
      allOpenCount
      psoCommentPeriodCounts {
        year
        openCount
        closedCount
        scheduledCount
      }
      publicCommentPeriodCounts {
        year
        openCount
        closedCount
        scheduledCount
      }
    }
  }
`;

export const GET_OPEN_AND_RECENT_COMMENTING_PERIODS = gql`
  query GetAllCommentingPeriods {
    getOpenAndRecentCommentingPeriods {
      id
      fvsId
      fvsCode
      fvsTitle
      fvsYear
      periodStart
      periodEnd
      userType
      status
      daysLeft
    }
  }
`;

export const GET_PRE_BID_DOCUMENTATION_REVIEW = gql`
  query GetPreBidReviewDocListForVendor($contractYear: Int!) {
    getPreBidReviewDocumentationForVendor(contractYear: $contractYear) {
      preBidReviewDocuments {
        fvsId
        reviewStatus
        isChecked
        fedVehicleStandard {
          id
          fedStandardCode
          fedStandard {
            title
          }
        }
      }
    }
  }
`;

export const GET_PRE_BID_SELECTIONS_COUNT = gql`
  query GetPreBidDataForVendor($contractYear: Int!) {
    getPreBidDataForVendor(contractYear: $contractYear) {
      contractYear
      counts {
        selectionCount
        reviewedSelectionCount
        docsCount
        reviewedDocsCount
      }
      selectionsForYear {
        contractYear
        selections {
          vehicleGroupCode
          selectedStandardItemIds
        }
      }
      docsReview {
        fvsId
        totalDocs
        reviewStatus
        contractYear
        isChecked
      }
    }
  }
`;

export const GET_PRE_BID_SELECTIONS_SUMMARY = gql`
  query GetPreBidSelections(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getPreBidSelections(
      filters: $filters
      order: $order
      limit: $limit
      offset: $offset
    ) {
      count
      hasMore
      rows {
        id
        contractYear
        vendorId
        vehicleGroupCode
        reviewStatus
        standardItem {
          id
          standardItemNumber
          title
          vehicleType
          fedStandardCode
        }
        preBidMakeModels {
          make {
            makeCode
            makeName
          }
          model {
            modelCode
            modelName
          }
          chassisMake {
            makeCode
            makeName
          }
          chassisModel {
            modelCode
            modelName
          }
        }
      }
    }
  }
`;

export const ADD_OR_UPDATE_PRE_BID_SELECTIONS = gql`
  mutation AddOrUpdateSelections($selections: PreBidSelectionsInput!) {
    addOrUpdatePreBidSelections(selections: $selections) {
      contractYear
      counts {
        selectionCount
        reviewedSelectionCount
        docsCount
        reviewedDocsCount
      }
      selectionsForYear {
        contractYear
        selections {
          vehicleGroupCode
          selectedStandardItemIds
        }
      }
      docsReview {
        fvsId
        totalDocs
        reviewStatus
        contractYear
      }
    }
  }
`;

export const MARK_DOC_AS_REVIEWED = gql`
  mutation MarkDocAsReviewed($fvsId: Int!) {
    markDocAsReviewed(fvsId: $fvsId) {
      reviewStatus
      fvsId
      isChecked
      totalDocs
      contractYear
    }
  }
`;

export const GET_MAKE_BY_PARTIAL_MATCH = gql`
  query GetMakesByPartialMatch($makeName: String!, $dataSource: [String!]) {
    getMakesByPartialMatch(makeName: $makeName, dataSource: $dataSource) {
      makeCode
      makeName
      approvalStatus
    }
  }
`;

export const GET_MODEL_BY_PARTIAL_MATCH = gql`
  query GetModelsByPartialMatch(
    $makeCode: Int!
    $modelName: String!
    $dataSource: [String!]
  ) {
    getModelsByPartialMatch(
      makeCode: $makeCode
      modelName: $modelName
      dataSource: $dataSource
    ) {
      modelCode
      modelName
      approvalStatus
    }
  }
`;

export const GET_PRE_BID_SELECTION_BY_STANDARD_ITEM_ID = gql`
  query GetPreBidSelectionByStandardItemId($standardItemId: Float!) {
    getPreBidSelectionByStandardItemId(standardItemId: $standardItemId) {
      id
    }
  }
`;

export const ADD_MAKE_AND_MODEL_FOR_SELECTION = gql`
  mutation AddMakeAndModelForSelection($input: PreBidMakeModelInput!) {
    addMakeAndModelForSelection(input: $input) {
      makeCode
      modelCode
    }
  }
`;

export const UPDATE_MAKE_MODEL = gql`
  mutation UpdateMakeAndModel($makeAndModel: MakeAndModelInput!) {
    updateMakeAndModel(makeAndModel: $makeAndModel)
  }
`;

export const UPDATE_PRE_BID_SELECTION_WITH_MAKE_AND_MODEL = gql`
  mutation updatePreBidSelectionsWithMakeAndMode(
    $makeAndModel: MakeAndModelInput!
  ) {
    updatePreBidSelectionsWithMakeAndMode(makeAndModel: $makeAndModel)
  }
`;

export const GET_PRE_BID_SELECTIONS = gql`
  query GetPreBidSelections(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getPreBidSelections(
      filters: $filters
      order: $order
      limit: $limit
      offset: $offset
    ) {
      count
      hasMore
      rows {
        id
        intentToBid
        preBidMakeModels {
          makeCode
          modelCode
        }
      }
    }
  }
`;
export const GET_PRE_BID_MAKE_MODEL_DATA = gql`
  query getPreBidMakeModelData(
    $filters: FilterType
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getPreBidMakeModelData(
      filters: $filters
      order: $order
      limit: $limit
      offset: $offset
    ) {
      count
      hasMore
      rows {
        id
        make {
          makeName
          makeCode
          approvalStatus
          fleetStatus
        }
        model {
          modelName
          modelCode
          approvalStatus
          fleetStatus
        }
        chassisMake {
          makeName
          makeCode
          approvalStatus
        }
        chassisModel {
          modelName
          modelCode
          approvalStatus
        }
        modelYear
        makeModelApproval {
          fleetApprovalStatus
        }
        preBidSelection {
          id
          standardItemId
          vendorId
          vendorDetail {
            vendorName
            emailAddress
          }
          vehicleGroupCode
          createdByUserInfo {
            id
            fullName
            email
          }
          contractYear
          reviewStatus
          createdAt
          updatedByUser
          intentToBid
          standardItem {
            id
            vehicleType
            standardItemNumber
            fedStandardCode
            year
            fedStandard {
              title
              id
            }
            vehicleTypeCode {
              id
              code
              title
            }
            title
            createdByUser
            updatedByUser
            contentId
            status
          }
          vehicleGroup {
            title
          }
        }
      }
    }
  }
`;
export const ADD_OR_UPDATE_INTENTION_TO_BID = gql`
  mutation AddOrUpdateIntentionToBid(
    $standardItemId: Float!
    $contractYear: Float!
    $hasIntentionToBid: Boolean!
  ) {
    addOrUpdateIntentionToBid(
      standardItemId: $standardItemId
      hasIntentionToBid: $hasIntentionToBid
      contractYear: $contractYear
    ) {
      id
      standardItemId
      intentToBid
    }
  }
`;

export const DELETE_MAKE_AND_MODEL_FOR_SELECTION = gql`
  mutation DeleteMakeAndModelForSelection($preBidSelectionId: Float!) {
    deleteMakeAndModelForSelection(preBidSelectionId: $preBidSelectionId)
  }
`;

export const DELETE_MAKE_AND_MODEL_BY_ID = gql`
  mutation DeleteMakeAndModelById($makeAndModelId: Float!) {
    deleteMakeAndModelById(makeAndModelId: $makeAndModelId)
  }
`;

export const ADD_STAGING_MAKE_MODEL = gql`
  mutation AddStagingMakeModel(
    $entrySource: String!
    $makeName: String!
    $modelName: String!
  ) {
    addStagingMakeModel(
      entrySource: $entrySource
      makeName: $makeName
      modelName: $modelName
    ) {
      make {
        makeName
        makeCode
      }
      modelName
      modelCode
      fleetStatus
      entrySource
    }
  }
`;
