import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Spinner,
  FlexView,
  ErrorMessage,
  Button,
  Alert,
  SelectDropdown,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';

import Breadcrumbs from '../../../widgets/breadcrumbs';
import {
  OPERATIONS,
  SUBJECTS,
  emDashUnicode,
} from '../../../utilities/constants';
import ToastMessage from '../../../components/Toast/toast';
import { GET_CONTRACT_LINES_BY_CRITERIA } from '../contract-line/lines-query';
import ContractLineActionsPannel from './contract-line-actions-panel';
import {
  ContractLineProvider,
  useContractLine,
} from './provider/contract-line-provider';
import SopContractLineTabs from './contract-line-tabs';
import NonSopContractLineTemplate from './non-sop-line-template/non-sop-contract-line-tab';
import LineHeader from './line-header';

import './helpers/contract-line-template.scss';
import OverlaySpinner from '../../../components/overlay-spinner';

const ContractLine = () => {
  const { contractHeaderId } = useParams();
  const {
    contractLineId,
    contractLine,
    getContractLineQueryResult: query,
    loading,
    saving,
    saveContractLineInput,
    alert,
    setAlert,
  } = useContractLine();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const ability = useAppAbility();
  const canUpdateContract = ability.can(OPERATIONS.Update, SUBJECTS.Contract);

  const tabIndex = searchParams.get('tabIndex');
  const [lineItems, setLineItems] = useState([]);

  const { data: dataLines } = useQuery(GET_CONTRACT_LINES_BY_CRITERIA, {
    variables: {
      criteria: { contractHeaderId },
      order: 'scheduleLine DESC',
      limit: 9999,
    },
  });

  const solicitaion = contractLine?.contractHeader?.solicitation;
  const purchaseTypeCode = solicitaion?.purchaseTypeCode;

  const [selectedLine, setSelectedLine] = useState(lineItems?.[0]?.value || '');

  const handleSelectedLineUpdate = (id) => {
    window.location.href = `/catalog/contract/${contractHeaderId}/contract-line-template/${id}?tabIndex=${tabIndex}`;
  };

  useEffect(() => {
    const lineItemsData = dataLines?.getContractLinesByCriteria?.rows?.map(
      (item) => ({ value: item.id, label: item.scheduleLine }),
    );

    if (lineItemsData) {
      const currentLineItem = lineItemsData.find(
        (lineItem) => lineItem.value === contractLineId,
      );
      const restItems = lineItemsData.filter(
        (item) => item.value !== contractLineId,
      );
      const sortedItems = [currentLineItem, ...restItems];

      setLineItems([...sortedItems]);
    }
  }, [dataLines]);

  const breadcrumbs = (
    <Breadcrumbs
      path={[
        {
          location: `${window.AFP_CONFIG.appURLs.home}/home`,
          label: 'Home',
        },
        {
          location: `/catalog/contract`,
          label: 'Contracts',
        },
        {
          location: `/catalog/contract/contract-header/${contractHeaderId}/lines`,
          label: 'Contract line listing',
        },
      ]}
      current={`Line item ${contractLine?.scheduleLine || ''}`}
    />
  );

  if (query?.called && !query?.loading && !contractLine) {
    return (
      <>
        {breadcrumbs}
        <Alert type="error">Contract line {contractLineId} is not found</Alert>
      </>
    );
  }

  if (loading) {
    return (
      <>
        {breadcrumbs}
        <Spinner />
      </>
    );
  }

  const showSOP = purchaseTypeCode === 'S' || purchaseTypeCode === 'Y';
  const isNonStandard = purchaseTypeCode === 'N';
  const isMas = purchaseTypeCode === 'M';
  let subHeader = null;
  const showManufacturerNumber =
    (isNonStandard && contractLine?.manufacturerModelNumber) || isMas;

  if (showManufacturerNumber) {
    subHeader = (
      <div className="title-m">
        <span className=" title-m-bold">Manufacturer model number: </span>
        {contractLine?.manufacturerModelNumber || emDashUnicode}
      </div>
    );
  } else {
    subHeader = (
      <>
        <div className="margin-right-2 title-m">
          <span className=" title-m-bold">Body: </span>
          {contractLine?.make?.makeName || emDashUnicode}{' '}
          {contractLine?.model?.modelName || emDashUnicode}
        </div>
        {contractLine?.chassisMake && (
          <div className="title-m">
            <span className=" title-m-bold">Chassis: </span>
            {contractLine?.chassisMake?.makeName || emDashUnicode}{' '}
            {contractLine?.chassisModel?.modelName || emDashUnicode}
          </div>
        )}
      </>
    );
  }

  return (
    <FlexView column>
      <FlexView>
        {loading && <Spinner data-testid="contract-line-spinner" />}
        {query?.error && (
          <ErrorMessage>
            An error occurred while loading the contract line data.
          </ErrorMessage>
        )}
      </FlexView>

      {saving && <OverlaySpinner />}

      {contractLine && (
        <>
          {breadcrumbs}
          {alert && (
            <ToastMessage
              type={alert.type}
              message={alert.message}
              onClose={() => setAlert(null)}
              closable
              className="margin-bottom-2"
            />
          )}

          <FlexView
            vAlignContent="bottom"
            grow
            className="margin-top-2 margin-bottom-2"
          >
            <div className="margin-bottom-2">
              <FlexView vAlignContent="center" className="margin-bottom-1">
                <div className="title-xl">
                  Line Item {contractLine?.scheduleLine}
                </div>
                <SelectDropdown
                  id="line-dropdown"
                  aria-label="line dropdown"
                  className="margin-top-0"
                  containerClassName="margin-top-0 margin-left-2"
                  onChange={(e) => {
                    setSelectedLine(e.target.value);
                    handleSelectedLineUpdate(e.target.value);
                  }}
                  options={lineItems || []}
                  value={selectedLine}
                />
              </FlexView>
              <FlexView className="margin-top-2">{subHeader}</FlexView>
            </div>
            <FlexView grow> </FlexView>
            <ContractLineActionsPannel contractHeaderId={contractHeaderId} />
          </FlexView>

          <LineHeader />

          <FlexView
            className="margin-top-6 margin-bottom-2"
            vAlignContent="center"
          >
            {canUpdateContract && (
              <>
                {showSOP ? (
                  <FlexView grow>
                    Upon making edits within any of the tabs below, please be
                    sure to click &quot;<b>Save all changes</b>&quot; before
                    exiting this line item.
                  </FlexView>
                ) : (
                  <FlexView grow>
                    Upon making edits below, please be sure to click &quot;
                    <b>Save all changes</b>&quot; before exiting this line item.
                  </FlexView>
                )}
                <FlexView>
                  <Button
                    label="Save all changes"
                    variant="primary"
                    onClick={saveContractLineInput}
                  />
                </FlexView>
              </>
            )}
          </FlexView>

          {showSOP ? <SopContractLineTabs /> : <NonSopContractLineTemplate />}
        </>
      )}
    </FlexView>
  );
};

const ContractLineTemplate = () => {
  return (
    <ContractLineProvider>
      <ContractLine />
    </ContractLineProvider>
  );
};

export default ContractLineTemplate;
