import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Modal, connectModal, Button, Alert } from '@gsa/afp-component-library';
import moment from 'moment';

import AddSopLine from './add-sop-line';
import {
  useSIMakeModelProvider,
  CREATE_SOP_CONTRACT_LINE,
  NON_STANDARD_LINE_TYPES,
} from '../make-model/si-make-model-panel-provider';
import AddNonStandardLine from './add-non-standard-line';
import AddNonStandardLineMass from './add-non-standard-line-mass';
import AddNonStandardLineExpress from './add-non-standard-line-express';
import { useAddContractLineProvider } from './add-contract-line-provider';
import { UPDATE_CONTRACT_HEADER } from '../../contract-header/contract-header.gql';

const renderStandardLine = (purchaseTypeCode, contractHeader) => {
  // enum('E','M','N','O','S','Y')
  switch (purchaseTypeCode) {
    case 'S':
      return <AddSopLine contractHeader={contractHeader} />;
    case 'M':
      return <AddNonStandardLineMass contractHeader={contractHeader} />;
    case 'E':
      return <AddNonStandardLineExpress contractHeader={contractHeader} />;
    default:
      // 'N', 'O', 'Y'
      return <AddNonStandardLine contractHeader={contractHeader} />;
  }
};

const AddContractLineModal = ({
  contractHeader,
  isOpen,
  onClose,
  onCreateSuccess,
}) => {
  const purchaseTypeCode = contractHeader?.solicitation?.purchaseTypeCode;
  const {
    getErrors,
    setDirtyFields,
    selectedStandardItem,
    bodyMake,
    bodyModel,
    hasChassis,
    chassisMake,
    chassisModel,
    allowCustom,
    bodyMakeInputValue,
    bodyModelInputValue,
    chassisMakeInputValue,
    chassisModelInputValue,
    modelYear,
  } = useSIMakeModelProvider();
  const {
    nonStandardLineType,
    manufacturerModelNumber,
    getManufacturerModelNumberErrors,
  } = useAddContractLineProvider();
  const [createError, setCreateError] = useState();
  const [updateContractHeader] = useMutation(UPDATE_CONTRACT_HEADER, {
    variables: {
      contractHeaderId: contractHeader?.contractHeaderId,
    },
  });
  const [createNewContractLine, createNewContractLineState] = useMutation(
    CREATE_SOP_CONTRACT_LINE,
    {
      onCompleted: (data) => {
        setCreateError(undefined);
        if (data?.createNewContractLine?.id) {
          onCreateSuccess(data.createNewContractLine);

          // if not published and registration is valid, publish header after adding line
          if (contractHeader.publishedInFleet === true) {
            return;
          }

          if (
            moment().isAfter(
              contractHeader.vendor.registrationExpirationDate,
              'day',
            )
          ) {
            return;
          }

          updateContractHeader({
            variables: {
              contractHeaderUpdateInput: {
                publishedInFleet: true,
              },
            },
          });

          return;
        }
        setCreateError('Failed to create contract line');
      },
      onError: (error) => {
        setCreateError(error.message);
      },
    },
  );

  const addNewContractLine = useCallback(() => {
    const variables = {
      contractHeaderId: contractHeader?.contractHeaderId,
      standardItemId: parseInt(selectedStandardItem?.id, 10),
      makeCode: parseInt(bodyMake?.value, 10),
      modelCode: bodyModel?.value,
      modelYear: parseInt(modelYear, 10),
      manufacturerModelNumber,
    };

    if (hasChassis) {
      variables.chassisMakeCode = parseInt(chassisMake?.value, 10);
      variables.chassisModelCode = chassisModel?.value;
    }

    if (allowCustom) {
      variables.makeName = bodyMakeInputValue;
      variables.modelName = bodyModelInputValue;
      if (hasChassis) {
        variables.chassisMakeName = chassisMakeInputValue;
        variables.chassisModelName = chassisModelInputValue;
      }
    }

    createNewContractLine({
      variables: {
        createNewContractLineInput: variables,
      },
    });
  }, [
    hasChassis,
    selectedStandardItem,
    bodyMake,
    bodyMakeInputValue,
    bodyModel,
    bodyModelInputValue,
    chassisMake,
    chassisMakeInputValue,
    chassisModel,
    chassisModelInputValue,
    modelYear,
    allowCustom,
    contractHeader?.contractHeaderId,
    createNewContractLine,
    manufacturerModelNumber,
  ]);

  const addLineItem = useCallback(() => {
    if (
      purchaseTypeCode === 'M' ||
      (purchaseTypeCode === 'N' &&
        nonStandardLineType ===
          NON_STANDARD_LINE_TYPES.MANUFACTURER_MODEL_NUMBER)
    ) {
      setDirtyFields(['standardItem', 'manufacturerModelNumber']);
      const errors = getErrors();
      const manufacturerModelNumberErrors = getManufacturerModelNumberErrors();
      const siError = errors?.find((e) => e.field === 'standardItem')?.message;
      const mmnError = manufacturerModelNumberErrors?.find(
        (e) => e.field === 'manufacturerModelNumber',
      );
      if (!siError && !mmnError) {
        addNewContractLine();
      }
    } else {
      setDirtyFields([
        'standardItem',
        'bodyMake',
        'bodyModel',
        'chassisMake',
        'chassisModel',
        'modelYear',
      ]);
      const errors = getErrors();
      if (!errors?.length) {
        addNewContractLine();
      }
    }
  }, [
    getErrors,
    addNewContractLine,
    manufacturerModelNumber,
    nonStandardLineType,
  ]);

  return (
    <Modal
      id="add-new-contract-line-modal"
      variant="extra-large"
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button
            data-testid="close-new-contract-line-btn"
            variant="unstyled"
            onClick={onClose}
            label="Cancel"
          />
          <Button
            data-testid="add-new-contract-line-btn"
            className="margin-left-2"
            type="button"
            variant="primary"
            onClick={addLineItem}
            label={
              createNewContractLineState.loading
                ? 'Adding...'
                : 'Add new line item'
            }
            disabled={createNewContractLineState.loading}
          />
        </>
      }
    >
      <h1 className="title-l" aria-label="Add line item">
        Add line item
      </h1>
      {createError && (
        <Alert type="error" slim>
          {createError}
        </Alert>
      )}

      {renderStandardLine(purchaseTypeCode, contractHeader)}
    </Modal>
  );
};

AddContractLineModal.propTypes = {
  contractHeader: PropTypes.shape({
    contractHeaderId: PropTypes.string,
    solicitation: PropTypes.shape({
      purchaseTypeCode: PropTypes.string,
    }),
    vendor: PropTypes.shape({
      registrationExpirationDate: PropTypes.string,
    }),
    publishedInFleet: PropTypes.bool,
  }),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onCreateSuccess: PropTypes.func,
};

AddContractLineModal.defaultProps = {
  contractHeader: {},
  isOpen: false,
  onClose: () => {},
  onCreateSuccess: () => {},
};

export default connectModal(AddContractLineModal);
