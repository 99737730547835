/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { AFPTable } from '@gsa/afp-component-library';
import { emDashUnicode } from '../../../utilities/constants';
import { formatDateTime } from './contract-header-history-helpers';

export const Columns = [
  {
    Header: 'Version',
    accessor: 'versionNumber',
    sortable: true,
    Cell: ({ value }) => {
      const { contractHeaderId } = useParams();
      return (
        <RouterLink
          to={`/catalog/contract/${contractHeaderId}/history/${value}`}
        >
          {value}
        </RouterLink>
      );
    },
  },
  {
    Header: 'Changed by',
    accessor: 'changedBy',
    sortable: true,
    Cell: ({ value }) => value,
  },
  {
    Header: 'Date/time of change',
    accessor: 'changedAt',
    sortable: true,
    Cell: ({ value }) => formatDateTime(value),
  },
  {
    Header: 'Number of changes',
    accessor: 'changeCount',
    sortable: false,
    Cell: ({ value }) => value || emDashUnicode,
  },
];

export const RowSubComponent = ({ row: { original } }) => {
  const changeColumns = [
    {
      Header: 'Field name',
      accessor: 'fieldName',
      sortable: false,
      Cell: ({ value }) => value,
    },
    {
      Header: 'Previous value',
      accessor: 'previousValue',
      sortable: false,
      Cell: ({ value }) => value,
    },
    {
      Header: 'Current value',
      accessor: 'currentValue',
      sortable: false,
      Cell: ({ value }) => value,
    },
  ];

  if (original.changeCount === 0) return null;

  return (
    <section className="contract-history-table-subrow">
      <h3 className="text-primary margin-0">CONTRACT VERSION CHANGES</h3>
      <AFPTable data={original.changes} columns={changeColumns} />
    </section>
  );
};

RowSubComponent.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      standardItem: PropTypes.shape({
        vehicleGroup: PropTypes.shape({
          code: PropTypes.string,
          title: PropTypes.string,
        }),
        vehicleType: PropTypes.string,
        vehicleTypeCode: PropTypes.shape({
          title: PropTypes.string,
        }),
        standardItemNumber: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
  }).isRequired,
};
